import {CombinedState, combineReducers, Reducer} from "redux";
import {dashboardReducer} from "./dashboard/dashboard.reducer";
import {RootAction} from "./types";
import {navigationReducer} from "./navigation/navigation.reducer";
import {NavigationState} from "./navigation/types";
import {alertsReducer} from "./alerts/alerts.reducer";
import {AlertsState} from "./alerts/types";
import {configReducer} from "./config/config.reducer";
import {ConfigState} from "./config/types";

const allReducers = combineReducers({
    dashboard: dashboardReducer,
    navigation: navigationReducer,
    alerts: alertsReducer,
    config: configReducer,
})

const rootReducer: Reducer<CombinedState<
    {
        dashboard: never
        navigation: NavigationState
        alerts: AlertsState
        config: ConfigState
    }>> = (
    state,
    action
) => {
    if (action.type === RootAction.REDIRECT) {
        window.location = action.payload;
        return allReducers(state, action);
    }
    return allReducers(state, action)
}

export { rootReducer }
