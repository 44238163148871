import {ConfirmationMessage} from "../../api/types";

export interface NavigationState {
    challengeNavigation: ConfirmationMessage | null
}

export enum NavigationAction {
    SET_CHALLENGE = 'SET_NAVIGATION_CHALLENGE',
    CLEAR_CHALLENGE = 'CLEAR_NAVIGATION_CHALLENGE',
}
