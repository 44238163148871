import React, {ReactElement, useCallback} from "react";
import styles from './TabSelector.styles.module.scss';
import {TabSelectorProps} from "./types";
import {useConfirmModal} from "../ConfirmModal";

const TabSelector = (
    {
        tabs,
        selection,
        bindToNavigationChallenge,
        doClearNavigationChallenge,
        challengeNavigation,
        onSelectionChange,
    }: TabSelectorProps): ReactElement => {
    const [ConfirmModal, openConfirmModal] = useConfirmModal({})
    const handleClick = useCallback((index) => () => {
        if (index === selection) {
            return
        }
        if (bindToNavigationChallenge && challengeNavigation) {
            openConfirmModal(challengeNavigation)
                .then(() => {
                    doClearNavigationChallenge()
                    onSelectionChange?.(index)
                }, () => null)
            return
        }
        onSelectionChange?.(index)
    }, [bindToNavigationChallenge, onSelectionChange, challengeNavigation])
    return (
        <div className={styles.container}>
            {tabs.map((tab, index) => (
                <div
                    className={`
                        ${styles.tab}
                        ${selection === index ? styles.selected : ''}
                    `}
                    key={tab}
                    onClick={handleClick(index)}
                >
                    {tab}
                </div>
            ))}
            <ConfirmModal />
        </div>
    )
}

export { TabSelector }
