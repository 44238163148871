import React, {FC} from 'react';
import styles from '../../WelcomeModal.styles.module.scss';
import onboardingImage from './onboardingOneImage.jpg';

const OnboardingOne: FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.pageHeading}>
                Hey, how are you today?
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.onboardingBody}>
                        At 11am each day, team members will be asked a simple question - ‘Hey, how are you today?’
                        <br></br>
                        <br></br>
                        Each team member will be able to respond directly in Slack with a score from 1 to 10.
                        <br></br>
                        <br></br>
                        Their answer will be sent and stored securely on the HappyTrack dashboard.
                    </div>
                    <div className={styles.image}>
                        <img src={onboardingImage} alt="Onboarding Image"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { OnboardingOne }
