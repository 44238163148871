import React, {ReactElement} from 'react'
import {UserLabelProps} from "./types";
import styles from './UserLabel.styles.module.scss'
import textStyles from '../../styles/_text.module.scss'
import {UserAvatar} from "../UserAvatar";

const UserLabel = (
    {
        className,
        user,
    }: UserLabelProps
): ReactElement => (
    <div className={`
        ${styles.userLabel}
        ${className && className}
        `}>
        <UserAvatar user={user}/>
        <div className={styles.userName}>
            <span className={textStyles.body}>
                {user.displayName}
            </span>
        </div>
    </div>
)

export { UserLabel }
