import React, {PropsWithChildren, ReactElement} from "react";
import {CardProps} from "./types";
import styles from './Card.styles.module.scss'

const Card = (
    {
        className,
        children,
        variableHeight,
    }: PropsWithChildren<CardProps>
): ReactElement => (
    <div className={`
        ${styles.card}
        ${className || ''}
        ${variableHeight ? styles.variableHeight : ''}
    `}>
        {children}
    </div>
)

export { Card }
