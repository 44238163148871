import React, {FC} from "react";
import styles from "../../WelcomeModal.styles.module.scss";
import onboardingImage from './onboardingTwoImage.png';

const OnboardingTwo: FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.pageHeading}>
                Weekly insights
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.onboardingBody}>
                        Each week, Managers will receive a Slack notification with the teams average score.
                        <br></br>
                        <br></br>
                        They’ll also be notified about team member’s whose scores are lower than usual.
                        <br></br>
                        <br></br>
                        More detailed information can always be found on the HappyTrack Dashboard.
                    </div>
                    <div className={styles.image}>
                        <img src={onboardingImage} alt="Onboarding image"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { OnboardingTwo }
