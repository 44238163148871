import React, {PropsWithChildren, ReactElement} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login/Login";
import { Dashboard } from "./pages/Dashboard";
import globalStyles from './styles/_global.module.scss';
import {FullWidth} from "./components/Layout";
import {NavigationBar} from "./components/NavigationBar";
import {Settings} from "./pages/Settings";
import {Endpoint} from "./pages/types";
import {AppProps} from "./types";
import {Unauthorized} from "./pages/Unauthorized/Unauthorized";
import {Modal} from "./components/Modal";
import {WelcomeModal} from "./components/WelcomeModal";

const App = (
    {
        isOnboarding,
        doStopOnboarding,
    }: PropsWithChildren<AppProps>
): ReactElement => {

    return (
        <div className={globalStyles.app}>
            <BrowserRouter>
                <NavigationBar/>
                <FullWidth>
                    <Switch>
                        <Route path={Endpoint.unauthorized}>
                            <Unauthorized />
                        </Route>
                        <Route path={Endpoint.login}>
                            <Login />
                        </Route>
                        <Route path={Endpoint.dashboard}>
                            <Dashboard />
                        </Route>
                        <Route path={Endpoint.settings}>
                            <Settings />
                        </Route>
                    </Switch>
                </FullWidth>
                <Modal isOpen={isOnboarding} hideCloseButton onClose={doStopOnboarding}>
                    <WelcomeModal />
                </Modal>
            </BrowserRouter>
        </div>
    )
}

export { App }
