import {ScoreData} from "../../api/types";

export const monthlyAverage = (scores: ScoreData[]): { date: Date; score: number }[] => {
    const today = new Date();
    const startDate: Date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 30
    );
    const newData = scores.filter((score: ScoreData) => {
        return new Date(score.date) > startDate;
    });

    return newData.map((data) => {
        return {
            date: data.date,
            score:
                data.scores.reduce((score, current) => {
                    return score + current.score;
                }, 0) / (data.scores.length || 1),
        };
    });
}

