import {DashboardData} from "../../api/types";

export interface DaysConfig {
    dayRange: number
}

export interface DashboardState extends DaysConfig {
    data: DashboardData | null
    isLoading: boolean
    isAuthorised: boolean
}

export enum DashboardAction {
    START = 'SET_DASHBOARD_START',
    SUCCESS = 'SET_DASHBOARD_SUCCESS',
    FAILURE = 'SET_DASHBOARD_FAILURE',
}
