import React, {ReactElement} from 'react'
import styles from './UserPill.styles.module.scss'
import {PillButton, UserPillProps} from "./types";
import {UserLabel} from "../../UserLabel";
import {MinusIcon} from "../../../icons/minus";
import {useUserPillHook} from "./UserPill.hooks";
import {CloseIcon} from "../../../icons/close";
import {AddIcon} from "../../../icons/add";

const UserPill = (props: UserPillProps): ReactElement => {
    const {
        className,
        user,
        action,
        modification,
        onClick,
        button,
    } = useUserPillHook(props)
    return (
        <div className={`
            ${styles.pill}
            ${action && styles[action]}
            ${modification ? styles[modification] : ''}
            ${action === modification && styles.reverse}
            ${className}
        `}>
            <div className={styles.label}>
                <UserLabel user={user}/>
            </div>
            {onClick && (
                <div className={styles.button} onClick={() => onClick?.()}>
                    {button === PillButton.minus && (
                        <MinusIcon size={12}/>
                    )}
                    {button === PillButton.close && (
                        <CloseIcon size={12}/>
                    )}
                    {button === PillButton.add && (
                        <AddIcon size={12}/>
                    )}
                </div>
            )}
        </div>
    )
}

export { UserPill }
