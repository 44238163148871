import React, {ReactElement} from 'react'
import colors from '../../styles/_colors.module.scss'
import {IconProps} from "../types";

const ConfigIcon = ({
    size = 33,
    color = colors.pageBackground,
    inheritFill,
}: IconProps): ReactElement => {
    const fillStyle = inheritFill ? {} : { fill: color }
    return (
        <svg width={size} height={size} viewBox="0 -0.5 33 33">
            <g fillRule="evenodd">
                <g {...fillStyle} fillRule="nonzero">
                    <g>
                        <path d="M31.417 13.371l-4.112-.672c-.24-.825-.57-1.618-.981-2.369l2.4-3.42c.194-.276.16-.653-.078-.892L25.94 3.314c-.241-.24-.62-.272-.898-.072l-3.37 2.421c-.757-.418-1.557-.751-2.387-.992L18.568.576C18.509.243 18.22 0 17.883 0h-3.827c-.34 0-.632.247-.686.584l-.666 4.072c-.834.24-1.636.569-2.39.982l-3.361-2.4c-.278-.198-.655-.166-.897.074L3.352 6.016c-.239.239-.272.614-.078.89l2.365 3.383c-.421.762-.756 1.568-1 2.407l-4.057.676c-.336.055-.582.346-.582.686v3.826c0 .337.242.626.574.685l4.057.72c.242.836.578 1.642 1 2.406l-2.394 3.348c-.197.277-.166.656.074.897l2.705 2.707c.238.239.615.272.891.078l3.388-2.373c.76.418 1.564.75 2.397.99l.679 4.078c.055.334.345.58.685.58h3.827c.337 0 .626-.242.684-.574l.727-4.097c.834-.246 1.634-.58 2.386-.999l3.413 2.394c.277.196.653.162.892-.077l2.705-2.707c.24-.241.272-.622.072-.899l-2.433-3.378c.412-.752.74-1.546.978-2.371l4.118-.723c.333-.058.575-.348.575-.685v-3.826c0-.341-.246-.632-.583-.687zM16 21.6c-2.651 0-4.8-2.328-4.8-5.2 0-2.872 2.149-5.2 4.8-5.2s4.8 2.328 4.8 5.2c0 2.872-2.149 5.2-4.8 5.2z" transform="translate(-18 -112) translate(18.718 112)"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { ConfigIcon }
