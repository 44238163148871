import React, {ReactElement} from 'react'
import {ConfirmModalProps} from "./types";
import {Modal} from "../Modal";
import styles from './ConfirmModal.styles.module.scss';

const ConfirmModal = <PassThroughProps, >(
    {
        isOpen,
        message,
        onClickConfirm,
        onClickCancel,
        children,
        passThrough,
    }: ConfirmModalProps<PassThroughProps>
): ReactElement => (
    <Modal isOpen={isOpen} hideCloseButton={true} autoSize={true}>
        <div className={styles.container}>
            {message?.title && (
                <div className={styles.title}>
                    {message.title}
                </div>
            )}
            {!!children && typeof children === 'function' && children(passThrough)}
            {!!children && typeof children !== 'function' && children}
            {!children && (
                <div className={styles.message}>
                    {message?.message || 'Do you wish to continue?'}
                </div>
            )}
            <div className={styles.actions}>
                <button
                    className={styles.secondaryButton}
                    onClick={onClickCancel}
                >
                    {message?.cancel || 'No'}
                </button>
                <button
                    className={styles.primaryButton}
                    onClick={onClickConfirm}
                >
                    {message?.confirm || 'Yes'}
                </button>
            </div>
        </div>
    </Modal>
)

export { ConfirmModal }
