import {RootState} from "../../redux/types";
import {getUserName} from "../../redux/dashboard/dashboard.selectors";
import {getIsOnboarding} from "../../redux/config/config.selectors";
import {ThunkDispatch} from "redux-thunk";
import {DashboardState} from "../../redux/dashboard/types";
import {Action} from "redux";
import {startOnboarding, stopOnboarding} from "../../redux/config/config.actions";
import {connect} from "react-redux";
import {WelcomeModalStateProps} from "./type";
import {WelcomeModal} from "./WelcomeModal";

const mapStateToProps = (state: RootState): WelcomeModalStateProps => ({
    userName: getUserName(state),
    isOnboarding: getIsOnboarding(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch<DashboardState, null, Action>) => ({
    doStopOnboarding: () => dispatch(stopOnboarding()),
    doStartOnboarding: () => dispatch(startOnboarding()),
})

const WelcomeModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WelcomeModal)

export { WelcomeModalContainer }
