import {ModifiedUser, ModifyAction, UserModification} from "../types";

export type PillAction = UserModification | ModifyAction

export enum PillButton {
    none,
    add,
    minus,
    close,
}

export interface UserPillProps extends ModifiedUser {
    className?: string
    onClick?: () => void
}

export interface UserPillHookProps extends UserPillProps {
    button: PillButton
    action: PillAction
}
