import {CellType, ScoreTableRowProps} from "./type";
import {Row} from "../Layout";
import styles from "./ScoreTable.styles.module.scss";
import {Size} from "../Layout/types";
import {DashboardUser, Day, Score} from "../../api/types";
import {DayCell} from "./DayCell";
import {ScoreCell} from "./ScoreCell";
import React, {ReactElement} from "react";
import {UserCell} from "./UserCell";

const ScoreTableRow = ({ row }: ScoreTableRowProps): ReactElement => (
    <Row className={styles.tableRow} size={Size.full}>
        {row.map((
            {type, content, style},
            index
        ) => (
            <React.Fragment key={`cell_${index}`}>
                {type === CellType.blank && <div className={styles.blankCell}/>}
                {type === CellType.empty && <div className={styles.emptyCell}/>}
                {type === CellType.pending && <div className={styles.pendingCell}/>}
                {type === CellType.user && <UserCell user={content as DashboardUser}/>}
                {type === CellType.date && <DayCell day={content as Day} />}
                {type === CellType.score && <ScoreCell score={content as Score} style={style}/>}
            </React.Fragment>
        ))}
    </Row>
)

export { ScoreTableRow }
