import {Action, AnyAction} from "redux";
import {NavigationAction} from "./types";
import {ConfirmationMessage} from "../../api/types";

export const setNavigationChallenge = (payload: ConfirmationMessage): AnyAction => ({
    type: NavigationAction.SET_CHALLENGE,
    payload,
})

export const clearNavigationChallenge = (): Action => ({
    type: NavigationAction.CLEAR_CHALLENGE,
})
