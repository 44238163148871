import { useState, useMemo, useEffect, useRef, useCallback } from "react";

export interface SearchHookInputProps<SearchType> {
    fetchSearchData: (phrase: string) => SearchType[] | Promise<SearchType[]>
    processSearchData?: (data: SearchType[], searchPhrase?: string) => SearchType[]
    searchableString: (item: SearchType) => string
    minSearchPhraseLength?: number
}

export interface SearchHookResultProps<SearchType> {
    searchPhrase: string
    searchResults: SearchType[]
    setSearchPhrase: (phrase: string) => void
    isSearching: boolean
    isEmpty: boolean
    clearSearch: () => void
}

const useSearch = <SearchType>(
    {
        fetchSearchData,
        processSearchData,
        searchableString,
        minSearchPhraseLength: minLength,
    }: SearchHookInputProps<SearchType>
): SearchHookResultProps<SearchType> => {
    const minSearchPhraseLength = typeof minLength === 'undefined' ? 3 : minLength
    const [searchPhrase, setSearchPhrase] = useState<string>('')
    const [baseData, setBaseData] = useState<SearchType[]>([])
    const [processedData, setProcessedData] = useState<SearchType[]>([])
    const lastSearchPhrase = useRef(searchPhrase)

    const isSearching = useMemo(
        () => searchPhrase.length >=minSearchPhraseLength,
        [searchPhrase])

    const clearSearch = useCallback(() => setSearchPhrase(''), [])

    useEffect(() => {
        if (!isSearching) {
            return
        }
        const newSearchBasePhrase = searchPhrase.slice(0, minSearchPhraseLength)
        if (newSearchBasePhrase !== lastSearchPhrase.current.slice(0, minSearchPhraseLength)) {
            const fetchData = fetchSearchData(newSearchBasePhrase)
            if (fetchData instanceof Promise) {
                fetchData.then(data => setBaseData(data))
            } else {
                setBaseData(fetchData)
            }
            lastSearchPhrase.current = searchPhrase
        }
    }, [searchPhrase, lastSearchPhrase, isSearching])

    useEffect(() => {
        if (!isSearching) {
            return
        }
        setProcessedData(typeof processSearchData === 'function'
            ? processSearchData(baseData, searchPhrase)
            : baseData
        )
    }, [baseData, searchPhrase, isSearching])

    const searchResults: SearchType[] = useMemo(() => {
            if (!isSearching) {
                return []
            }
            return processedData.filter(item => searchableString(item).toLowerCase().includes(searchPhrase.toLowerCase()))
        },
        [searchPhrase, processedData, isSearching]
    )

    const isEmpty = useMemo(() => isSearching && !searchResults.length, [isSearching, searchResults])

    return {
        searchResults,
        setSearchPhrase,
        isSearching,
        isEmpty,
        clearSearch,
        searchPhrase
    }
}

export { useSearch }
