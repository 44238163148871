import React, {ReactElement} from "react";
import styles from "./AverageScoreGraph.styles.module.scss";
import {ScoreGraph} from "../ScoreGraph";
import {useAverageScoreGraphHook} from "./AverageScoreGraph.hooks";
import {AverageScoreGraphProps} from "./types";

const AverageScoreGraph = (props: AverageScoreGraphProps): ReactElement => {
    const {
        scores,
    } = useAverageScoreGraphHook(props)
    return (
        <div className={styles.container}>
            <div className={styles.graphHeading}>
                Average scores for team
            </div>
            {scores.length > 0 && (
                <ScoreGraph scores={scores}/>
            )}
            {scores.length == 0 && (
              <div>No scores to display</div>
            )}
        </div>
    )
}

export { AverageScoreGraph }
