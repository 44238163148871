import {Action} from "redux";
import {ConfigAction} from "./types";

export const startOnboarding = (): Action => ({
    type: ConfigAction.START_ONBOARDING,
})

export const stopOnboarding = (): Action => ({
    type: ConfigAction.STOP_ONBOARDING,
})
