import styles from "./SearchableInput.styles.module.scss";
import React, {FormEvent, ReactElement} from "react";
import {SearchableInputProps} from "./types";
import {useSearchableInputHook} from "./SearchableInput.hooks";

const SearchableInput = <SearchType, >(props: SearchableInputProps<SearchType>): ReactElement => {
    const {
        placeholder,
        setSearchPhrase,
        searchResults,
        isSearching,
        isEmpty,
        emptyMessage,
        clearSearch,
        searchPhrase,
        children,
        resultsContainerClassName,
        selectedIndex,
    } = useSearchableInputHook(props)
    return(
        <div className={isSearching ? styles.showingResults : ''}>
            {isSearching && (!isEmpty || (isEmpty && !!emptyMessage)) && <div
                className={styles.modalUnderlay}
                onClick={clearSearch}
            />}
            <input
                className={styles.input}
                value={searchPhrase}
                placeholder={`${placeholder || 'Search...'}`}
                onChange={(event: FormEvent<HTMLInputElement>) =>
                    setSearchPhrase(event.currentTarget.value)
                }
            />
            {isSearching && (
                <div className={styles.popover}>
                    <div className={resultsContainerClassName}>
                        {isEmpty && !!emptyMessage && emptyMessage(searchPhrase)}
                        {searchResults.map((result, index) => children({
                            result,
                            clearSearch,
                            isSelected: index === selectedIndex,
                        }))}
                    </div>
                </div>
            )}
        </div>
    )
}

export { SearchableInput }
