import React, {PropsWithChildren, ReactElement} from "react";
import {LayoutProps} from "./types";
import styles from "./Layout.styles.module.scss";

const Layout = (
    {
        baseStyle,
        className,
        size,
        sizeStyle,
        children,
    }: PropsWithChildren<LayoutProps & {
        baseStyle: string,
        sizeStyle?: string
    }>
) => {
    const additionalStyles = {
        ...(sizeStyle ? {[sizeStyle]: size } : {})
    }
    return (
        <div className={`
        ${baseStyle}
        ${className || ''}
    `}
             style={additionalStyles}
        >
            {children}
        </div>
    )
}

export const FullWidth = (props: PropsWithChildren<LayoutProps>): ReactElement =>
    <Layout baseStyle={`${styles.fullWidth} fullWidthLayout`} {...props} />

export const PageWidth = (props: PropsWithChildren<LayoutProps>): ReactElement =>
    <Layout baseStyle={`${styles.pageWidth} pageWidthLayout`} {...props} />

export const Column = (props: PropsWithChildren<LayoutProps>): ReactElement =>
    <Layout baseStyle={styles.column} {...props} sizeStyle={'width'}/>

export const Row = (props: PropsWithChildren<LayoutProps>): ReactElement =>
    <Layout baseStyle={styles.row} {...props} sizeStyle={'height'}/>
