import React, {ReactElement} from 'react';
import styles from './Unauthorized.styles.module.scss';

const Unauthorized = (): ReactElement => (
    <div className={styles.container}>
        <div className={styles.text}>
            You don’t have permission to view this dashboard.
        </div>
        <br></br>
        <div className={styles.text}>
            If you think you should have access, please speak to your manager.
        </div>
    </div>
)

export { Unauthorized }
