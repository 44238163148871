const dateOnly = (date: Date): number => date.setHours(0, 0, 0, 0)

const daysBetween = (firstDate: Date, secondDate: Date): number =>
    Math.round((dateOnly(firstDate) - dateOnly(secondDate)) / 86400000)

const getDayOfWeek = (date: Date, showRelationship = false): string => {
    const timeDifference = daysBetween(date, new Date())
    if (showRelationship && timeDifference === 1) {
        return 'Tomorrow'
    }
    if (showRelationship && timeDifference === 0) {
        return 'Today'
    }
    if (showRelationship && timeDifference === -1) {
        return 'Yesterday'
    }
    const day = date.getDay()
    return [
        'Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'
    ][day] || ''
}

const dayAndMonth = (date: Date): string => {
    const day = `0${date.getDate()}`.slice(-2)
    const month = `0${date.getMonth() + 1}`.slice(-2)
    return `${day}/${month}`
}

export { getDayOfWeek, dayAndMonth, dateOnly }
