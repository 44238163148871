import { NavigationItem } from "./NavigationItem";
import { withRouter } from 'react-router'
import {RootState} from "../../redux/types";
import {NavigationStateProps} from "./types";
import {getNavigationChallenge} from "../../redux/navigation/navigation.selectors";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {NavigationState} from "../../redux/navigation/types";
import {clearNavigationChallenge} from "../../redux/navigation/navigation.actions";

const mapStateToProps = (state: RootState): NavigationStateProps => ({
    challengeNavigation: getNavigationChallenge(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch<NavigationState, null, Action>) => ({
    doClearNavigationChallenge: () => dispatch(clearNavigationChallenge()),
})

const NavigationItemContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationItem))

export { NavigationItemContainer }
