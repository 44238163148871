import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {Action, AnyAction} from "redux";
import DashboardService from "../../api/DashboardService";
import {setRedirect} from "../root-actions";
import {Alert} from "../../api/types";
import {AlertsState, CurrentAlertsAction} from "./types";

export const setCurrentAlertsStart = (): Action => ({
    type: CurrentAlertsAction.START,
})

export const setCurrentAlertsSuccess = (payload: Alert[]): AnyAction => ({
    type: CurrentAlertsAction.SUCCESS,
    payload,
})

export const setCurrentAlertsFailure = (payload: Error): AnyAction => ({
    type: CurrentAlertsAction.FAILURE,
    payload,
})

export const doGetCurrentAlerts = (): ThunkAction<Promise<void>, AlertsState, null, AnyAction> => {
    return async (dispatch: ThunkDispatch<AlertsState, null, Action>): Promise<void> => {
        dispatch(setCurrentAlertsStart())
        DashboardService.getCurrentAlerts()
            .then((response) => {
                const redirect = response.headers["x-redirect"];
                if (redirect) {
                    dispatch(setRedirect(redirect))
                }
                dispatch(setCurrentAlertsSuccess(
                    response.data.alerts.map(alert => alert as Alert),
                ))
            })
            .catch((error) => {
                dispatch(setCurrentAlertsFailure(error))
            })
    }
}
