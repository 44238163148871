import {connect} from "react-redux";
import {UserScoreGraph} from "./UserScoreGraph";
import {RootState} from "../../redux/types";
import {UserScoreGraphStateProps} from "./types";
import {createGetScoresForUserSelector} from "../../redux/dashboard/dashboard.selectors";

const mapStateToProps = (state: RootState): UserScoreGraphStateProps => ({
    getScoresForUser: createGetScoresForUserSelector(state)
})

const UserScoreGraphContainer = connect(
    mapStateToProps,
)(UserScoreGraph)

export { UserScoreGraphContainer }
