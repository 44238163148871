import {OpenConfirmModal, UseConfirmModalOutputProps} from "./types";
import {ConfirmModal} from "./ConfirmModal";
import {ConfirmationMessage} from "../../api/types";
import React, {useCallback, useState, useMemo} from "react";

const useConfirmModal = <PassThroughProps, >(
    initialState: PassThroughProps
): UseConfirmModalOutputProps<PassThroughProps> => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [message, setMessage] = useState<ConfirmationMessage | undefined>()
    const [passThrough, setPassThrough] = useState<PassThroughProps>(initialState)
    const [onClickConfirm, setOnClickConfirm] = useState<() => void | undefined>()
    const [onClickCancel, setOnClickCancel] = useState<() => void | undefined>()

    const openConfirm = useCallback<OpenConfirmModal<PassThroughProps>>((
        message,
        passThroughProps
    ) => {
        setIsOpen(true)
        setMessage(message)
        if (passThroughProps) {
            setPassThrough(passThroughProps)
        }
        return new Promise<void>((resolve, reject) => {
            setOnClickConfirm(() => () => {
                setIsOpen(false)
                resolve()
            })
            setOnClickCancel(() => () => {
                setIsOpen(false)
                reject()
            })
        })
    }, [setIsOpen, setMessage, setOnClickConfirm, setOnClickCancel])

    return useMemo<UseConfirmModalOutputProps<PassThroughProps>>(() => {
        const confirmModalProps = {
            isOpen,
            message,
            passThrough,
            onClickConfirm,
            onClickCancel,
        }
        return [
            props => <ConfirmModal
                {...props.passThrough ? { passThrough: props.passThrough } : {}}
                {...confirmModalProps}
            >
                {props.children}
            </ConfirmModal>,
            openConfirm,
        ]
    }, [isOpen, openConfirm])
}

export { useConfirmModal }
