import {Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import colors from "../../styles/_colors.module.scss";
import {dayAndMonth} from "../../helpers/dates";
import React, {ReactElement} from "react";
import {ScoreGraphProps} from "./types";

const ScoreGraph = ({ scores }: ScoreGraphProps): ReactElement => {
    return     (<ResponsiveContainer width="100%" height={400}>
        <ComposedChart
            width={300}
            height={500}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            data={scores}
        >
            <defs>
                <linearGradient id="response-rate" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={colors.scoreGraph} stopOpacity={0.6}/>
                    <stop offset="95%" stopColor={colors.scoreGraph} stopOpacity={0.1}/>
                </linearGradient>
            </defs>
            <XAxis dataKey="date"
                   domain={['dataMin', "auto"]}
                   interval={"preserveEnd"}
                   tickFormatter={date => dayAndMonth(date)}
            />
            <YAxis dataKey="score" domain={[0, 10]} tickCount={11} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip labelFormatter={date => dayAndMonth(date)}/>
            <Line type="monotone"
                  dataKey="score"
                  dot={true}
                  stroke={colors.scoreGraph}
                  strokeWidth={2}
                  fill={colors.scoreGraph}
                  isAnimationActive={false}
            />
            <Area
                type="monotone"
                dataKey="score"
                strokeWidth={0}
                fillOpacity={1}
                fill="url(#response-rate)"
                isAnimationActive={false}
            />
        </ComposedChart>
    </ResponsiveContainer>)
}

export { ScoreGraph }
