import React, {ReactElement} from "react";
import {ScheduleSelectorProps, ScheduleType} from "./types";
import {useScheduleSelectorHook} from "./ScheduleSelector.hooks";
import styles from "./ScheduleSelector.styles.module.scss"
import {TimePicker} from "../TimePicker";

const ScheduleSelector = (props: ScheduleSelectorProps): ReactElement => {
  const {
    handleSubmitChanges,
    isLoading,
    showSave,
    newSchedule,
    dailyQuestionTime,
    dailyManagerReportTime,
    weeklyManagerReportTime,
    handleScheduleModification
  } = useScheduleSelectorHook(props)

  const itemChanged = (type: ScheduleType, time: string) => {
    console.log(`Item changed to value ${time}`)
    handleScheduleModification(type, time)
  }
  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.listTitle}>Loading schedule...</div>
      )}
      {!isLoading && (
        <>

          <div className={styles.listTitle}>Daily question</div>
          <div className={styles.pickerContainer}>
            <div className={styles.listTitle}>Every weekday at:</div>
            <div className={styles.timePicker} >
              <TimePicker defaultValue={dailyQuestionTime} onChange={(value => itemChanged(ScheduleType.dailyQuestion, value))}
                          name="Daily Question" step={60} />
            </div>
          </div>

          <div className={styles.listTitle}>Daily manager report</div>
          <div className={styles.pickerContainer}>
            <div className={styles.listTitle}>Every weekday at:</div>
            <div className={styles.timePicker}>
              <TimePicker defaultValue={dailyManagerReportTime} onChange={(value => itemChanged(ScheduleType.dailyManagerReport, value))}
                    name="Daily Manager Report" step={60} />
            </div>
          </div>


          <div className={styles.listTitle}>Weekly Manager Report</div>
          <div className={styles.pickerContainer}>
            <div className={styles.listTitle}>Each Monday at:</div>
            <div className={styles.timePicker}>
              <TimePicker defaultValue={weeklyManagerReportTime} onChange={(value => itemChanged(ScheduleType.weeklyManagerReport, value))}
                      name="Weekly Manager Report" step={60} />
            </div>
          </div>

          {showSave && (
            <div className={styles.actionsContainer}>
              <button
                className={styles.primaryButton}
                onClick={() => handleSubmitChanges?.(newSchedule)}
              >
                Save
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export { ScheduleSelector }