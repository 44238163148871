import {Score, ScoreData, User, DashboardUser, AvatarImages} from "./types";
import {ApiDashboardUser, ApiScore, ApiScoreData, ApiUser} from "./types.api";

const mapApiUserData = (user: ApiUser): User => ({
    id: user.id,
    userName: user.userName || user.name || 'Unknown',
    displayName: user.displayName || user.name || 'Unknown',
    avatarImages: user.avatarImages ? user.avatarImages as AvatarImages : undefined
})

const mapApiDashboardUserData = (user: ApiDashboardUser): DashboardUser => ({
    summary: mapApiUserData(user.summary),
    details: user.details,
})

const mapApiScoreToScoreForWithDate = (date: Date) => (score: ApiScore): Score => {
    return ({
        userId: score.userId,
        date,
        score: score.score,
    })
}

const mapApiScoreData = (scoreData: ApiScoreData): ScoreData => {
    const date = new Date(scoreData.date) || new Date()
    return {
        date,
        scores: scoreData.scores.map(mapApiScoreToScoreForWithDate(date))
    }
}

export { mapApiUserData, mapApiDashboardUserData, mapApiScoreToScoreForWithDate, mapApiScoreData }
