import {ReactElement} from "react";
import {User} from '../../api/types';
import {RouteComponentProps} from "react-router";
import {UserSearchSelectorProps} from "../../components/UserSearchSelector/types";
import {ConfirmationMessage} from "../../api/types";
import {ConfirmModalType} from "../../components/ConfirmModal/types";
import {ScheduleSelectorProps} from "../../components/ScheduleSelector/types";

export enum SettingsTabs {
    users = 'Users',
    managers = 'Managers',
    schedule = 'Schedule'
}

export interface SaveConfirmProps {
    listName?: string
    addList: User[]
    removeList: User[]
}

export interface ScheduleSaveConfirmProps {
    dailyQuestionTime: string
    dailyManagerTime: string
    weeklyManagerTime: string
}

export interface SettingsDispatchProps {
    doSetNavigationChallenge: (challenge: ConfirmationMessage) => void
    doClearNavigationChallenge: () => void
}

export interface SettingsProps extends SettingsDispatchProps, RouteComponentProps {}

export interface SettingsHookProps extends SettingsProps {
    TabSelector: () => ReactElement,
    SaveConfirmModal: ConfirmModalType<SaveConfirmProps>,
    ScheduleSaveConfirmModal: ConfirmModalType<ScheduleSaveConfirmProps>
    selected: string
    userSearchSelectorProps: UserSearchSelectorProps
    scheduleSelectorProps: ScheduleSelectorProps
    isUpdating: boolean
}
