import React from "react";
import ReactDOM from "react-dom";
import "./ui/styles/_global.module.scss";
import { App } from "./ui/index"
import {Provider} from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from './ui/redux/store'

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <App />
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
