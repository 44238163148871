import {SaveConfirmProps, ScheduleSaveConfirmProps, SettingsHookProps, SettingsProps, SettingsTabs} from "./types";
import {useCallback, useMemo, useState} from "react";
import {useTabSelector} from "../../components/TabSelector";
import {ModifiedUser, UserModification, UserSearchSelectorProps} from "../../components/UserSearchSelector/types";
import {User} from "../../api/types";
import DashboardService from "../../api/DashboardService";
import {useConfirmModal} from "../../components/ConfirmModal";
import {ScheduleSelectorProps} from "../../components/ScheduleSelector/types";
import {ApiScheduleData} from "../../api/types.api";
import Moment from "moment";

const useSettingsHook = (props: SettingsProps): SettingsHookProps => {
    const { doSetNavigationChallenge, doClearNavigationChallenge } = props

    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    const handleChangeSelection = useCallback(users => {
        if (users.length > 0) {
            doSetNavigationChallenge({
                message: `Are you sure you want to discard changes?`,
                cancel: `No, go back`
            })
        } else {
            doClearNavigationChallenge()
        }
    }, [doSetNavigationChallenge, doClearNavigationChallenge])

    const [TabSelector, selected] = useTabSelector({
        tabs: [SettingsTabs.users, SettingsTabs.managers, SettingsTabs.schedule],
        bindToNavigationChallenge: true,
    })

    const [SaveConfirmModal, openSaveConfirmModal] = useConfirmModal<SaveConfirmProps>({
        addList: [],
        removeList: [],
    })

    const [ScheduleSaveConfirmModal, openScheduleSaveConfirmModal] = useConfirmModal<ScheduleSaveConfirmProps>({
      dailyQuestionTime: '11:00',
      dailyManagerTime: '14:00',
      weeklyManagerTime: '10:00',
    })

    const filterUsersByModification = useCallback((modification: UserModification) => (user: ModifiedUser): boolean => {
        return user.modification === modification
    }, [])

    const handleSubmitChanges = useCallback(
        (newUserSet: User[], modifiedUsers: ModifiedUser[]) => {
            const addList = modifiedUsers
                .filter(filterUsersByModification(UserModification.adding))
                .map(user => user.user)
            const removeList = modifiedUsers
                .filter(filterUsersByModification(UserModification.removing))
                .map(user => user.user)

            openSaveConfirmModal({
                cancel: `No, go back`,
            }, {
                listName: selected.toLowerCase(),
                addList,
                removeList,
            })
                .then(() => {
                    const service = selected === SettingsTabs.users
                        ? DashboardService.setUsers
                        : DashboardService.setManagers
                    setIsUpdating(true)
                    service(newUserSet.map(user => user.id))
                        .then(() => {
                            setIsUpdating(false)
                            doClearNavigationChallenge()
                        })
                }, () => null)
        }, [selected, handleChangeSelection, openSaveConfirmModal])

    const userSearchSelectorProps = useMemo((): UserSearchSelectorProps => {
        return {
            userType: selected,
            currentUsersSource: selected === SettingsTabs.users
                ? DashboardService.getUsers
                : DashboardService.getManagers,
            newUsersSource: DashboardService.getAllSlackUsers,
            onSelectionChanged: handleChangeSelection,
            handleSubmitChanges,
        }
    }, [selected, handleChangeSelection, handleSubmitChanges])

    const handleSubmitSchedule = useCallback(
      (schedule: ApiScheduleData) => {
          openScheduleSaveConfirmModal({
              cancel: `No, go back`,
          }, {
            dailyQuestionTime: Moment(`${schedule.dailyQuestionHour}:00`, 'H:mm').format('HH:mm'),
            dailyManagerTime: Moment(`${schedule.dailyManagerReportHour}:00`, 'H:mm').format('HH:mm'),
            weeklyManagerTime: Moment(`${schedule.weeklyManagerReportHour}:00`, 'H:mm').format('HH:mm'),
          })
            .then(() => {
                setIsUpdating(true)
                DashboardService.setSchedule(schedule)
                  .then(() => {
                    setIsUpdating(false)
                    doClearNavigationChallenge()
                  })
            }, () => null)
      }, [selected, handleChangeSelection, openScheduleSaveConfirmModal])

    const scheduleSelectorProps = useMemo((): ScheduleSelectorProps => {
        return {
            scheduleSource: DashboardService.getSchedule,
            handleSubmitChanges: handleSubmitSchedule
        }
    }, [handleSubmitSchedule])

    return {
        ...props,
        TabSelector,
        SaveConfirmModal,
        ScheduleSaveConfirmModal,
        selected,
        userSearchSelectorProps,
        scheduleSelectorProps,
        isUpdating,
    }
}

export { useSettingsHook }
