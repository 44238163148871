import React, { ReactElement } from 'react'
import colors from '../../styles/_colors.module.scss'
import {IconProps} from "../types";

const HappyTrackIcon = ({
    size = 43,
    inheritFill,
}: IconProps): ReactElement => {
    const primaryFillStyle = !inheritFill ? { fill: colors.htRangeStart } : {}
    const secondaryFillStyle = !inheritFill ? { fill: colors.htRangeEnd } : {}
    return (
        <svg width={size} height={size} viewBox="0 -3.5 43 43">
            <g fillRule="evenodd">
                <g>
                    <g transform="translate(-149 -48) translate(149.286 48)">
                        <rect width="42.429" height="11.52" {...primaryFillStyle} rx="1.92"/>
                        <path {...secondaryFillStyle} d="M2.544 18.154l7.93 2.726c6.793 2.335 14.166 2.363 20.976.08l8.448-2.832c1.006-.337 2.094.205 2.431 1.21.066.197.1.403.1.61v7.47c0 .826-.528 1.559-1.31 1.821l-9.669 3.24c-6.81 2.284-14.183 2.256-20.976-.079l-9.178-3.155C.52 28.98 0 28.25 0 27.43v-7.46c0-1.06.86-1.92 1.92-1.92.212 0 .423.035.624.104z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { HappyTrackIcon }
