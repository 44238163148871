import { User } from '../../api/types'
import {SearchHookInputProps} from "../SearchableInput/Search.hook";
import {AxiosResponse} from "axios";
import {ApiUserData} from "../../api/types.api";

export enum UserModification {
    adding = "adding",
    removing = "removing",
}

export enum ModifyAction {
    reverse = 'reverse',
}

export interface UserSearchSelectorProps {
    className?: string
    placeholder?: string
    userType?: string
    currentUsersSource: () => Promise<AxiosResponse<ApiUserData>>
    newUsersSource: () => Promise<AxiosResponse<ApiUserData>>
    onSelectionChanged?: (users: ModifiedUser[]) => void
    handleSubmitChanges?: (newUserSet: User[], modifiedUsers: ModifiedUser[]) => void
}

export interface UserSearchSelectorHookProps extends UserSearchSelectorProps, SearchHookInputProps<ModifiedUser> {
    userType: string
    isLoading: boolean
    usersToModify: ModifiedUser[]
    newUserSet: User[]
    currentUsers: ModifiedUser[]
    handleUserModification: (user: ModifiedUser) => void
    modifyListTitle: string,
    isRemovingLastModifiedUser: boolean
}

export interface ModifiedUser {
    user: User
    modification: UserModification | undefined
    isRemoving?: boolean
    isCurrent?: boolean
}
