import {User} from '../../api/types'
import {
    ModifiedUser,
    ModifyAction,
    UserModification,
    UserSearchSelectorHookProps,
    UserSearchSelectorProps
} from "./types";
import {useEffect, useMemo, useState, useCallback} from "react";
import {
    getActionForUser,
    mapModifiedUsersByMergingExistingModifiedUsers,
    mapUserToExistingModifiedUser,
    mapUserToModifiedUser, sortAddedUsersFirst, sortUnModifiedUsersFirst
} from "./UserSearchSelector.helpers";
import animation from '../../styles/_animation.module.scss'
import {mapApiUserData} from "../../api/api.helpers";

const useUserSearchSelectorHook = (props: UserSearchSelectorProps): UserSearchSelectorHookProps => {
    const {
        userType,
        onSelectionChanged,
        currentUsersSource,
        newUsersSource,
    } = props
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ users, setUsers ] = useState<User[]>([])

    useEffect(() => {
        currentUsersSource()
            .then(response => setUsers(response.data.users?.map(mapApiUserData) || []))
            .then(() => setIsLoading(false))
    }, [currentUsersSource])

    const [ usersToModify, setUsersToModify ] = useState<ModifiedUser[]>([])
    const [handleAddUserModification, setHandleAddUserModification] = useState<
        (user: User, modification: UserModification) => void
        >(() => () => null)
    const [handleRemoveUserModification, setHandleRemoveUserModification] = useState<
        (user: User) => void
        >(() => () => null)

    const currentUsers = useMemo(() =>
        users.map(mapUserToExistingModifiedUser(usersToModify))
    , [users, usersToModify])

    const modifyListTitle = useMemo(() => {
        const actions = [
            ...usersToModify
                .find(user => user.modification === UserModification.adding)
            ? ['adding'] : [],
            ...usersToModify
                .find(user => user.modification === UserModification.removing)
            ? ['removing'] : [],
        ]
        return actions.join(' and ')
    }, [usersToModify])

    const isRemovingLastModifiedUser = useMemo(() => {
        if (usersToModify.length > 1) {
            return false
        }
        return usersToModify[0]?.isRemoving === true
    }, [usersToModify])

    const searchSelector = useCallback(user => `
        ${user.user.displayName} ${user.user.userName}
    `, [])

    const fetchSearchData = useCallback(() => newUsersSource()
            .then(response => {
                const currentUserIds = currentUsers.map(user => user.user.id)
                return [
                    ...currentUsers,
                    ...response.data.users
                        .map(mapApiUserData)
                        .filter(user => !currentUserIds.includes(user.id))
                        .map(mapUserToModifiedUser()),
                ]
            })
        , [currentUsers])

    const processSearchData = useCallback((receivedUsers) => receivedUsers
            .map(mapModifiedUsersByMergingExistingModifiedUsers(usersToModify))
            .sort(sortUnModifiedUsersFirst),
        [currentUsers, usersToModify])

    useEffect(() => {
        let _usersToModify: ModifiedUser[] = []

        setHandleAddUserModification(() => (user: User, modification: UserModification) => {
            _usersToModify = [
                ..._usersToModify.filter(modifiedUser => modifiedUser.user.id !== user.id),
                ...[{ user, modification }],
            ]
            setUsersToModify(_usersToModify)
        })

        setHandleRemoveUserModification(() => (user: User) => {
            _usersToModify = _usersToModify.map(modifiedUser => ({
                ...modifiedUser,
                isRemoving: modifiedUser.user.id === user.id,
            }))
            setUsersToModify(_usersToModify)
            setTimeout(() => {
                _usersToModify = _usersToModify.filter(modifiedUser => modifiedUser.user.id !== user.id)
                setUsersToModify(_usersToModify)
            }, Number(animation.halfDuration))
        })
    }, [])

    useEffect(() => onSelectionChanged?.(usersToModify), [usersToModify])

    const handleUserModification = useCallback((user: ModifiedUser) => {
        const action = getActionForUser(user)
        if (action === ModifyAction.reverse) {
            handleRemoveUserModification(user.user)
        } else {
            handleAddUserModification(user.user, action)
        }
    }, [handleAddUserModification, handleRemoveUserModification])

    const newUserSet = useMemo(() => {
        return [
            ...currentUsers.filter(user => user.modification !== UserModification.removing),
            ...usersToModify.filter(user => user.modification === UserModification.adding),
        ].map(user => user.user)
    }, [usersToModify])

    return {
        ...props,
        userType: userType || 'Users',
        isLoading,
        fetchSearchData,
        processSearchData,
        searchableString: searchSelector,
        minSearchPhraseLength: 3,
        modifyListTitle,
        currentUsers,
        newUserSet,
        usersToModify: useMemo(() => usersToModify.sort(sortAddedUsersFirst), [usersToModify]),
        isRemovingLastModifiedUser,
        handleUserModification,
    }
}

export { useUserSearchSelectorHook }
