import React, {ReactElement, useMemo} from 'react'
import {UserAvatarProps} from "./types"
import styles from './UserAvatar.styles.module.scss'

const UserAvatar = (
    {
        user,
    }: UserAvatarProps
): ReactElement => {
    const { displayName, avatarImages } = user
    const image = useMemo((): string | null => {
        if (avatarImages?.image_32) {
            return avatarImages.image_32
        } else if (avatarImages?.image_48) {
            return avatarImages.image_48
        } else if (avatarImages?.image_192) {
            return avatarImages.image_192
        } else if (avatarImages?.image_512) {
            return avatarImages.image_512
        } else if (avatarImages?.image_original) {
            return avatarImages.image_original
        } else
            return null
    }, [avatarImages])
    return (
        <div className={styles.container}>
            {image && (
                <img src={image} width={32} height={32}/>
            )}
            {!image && displayName.charAt(0).toUpperCase()}
        </div>
    )
}

export { UserAvatar }
