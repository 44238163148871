import {createSelector} from "reselect";
import {getDashboardState} from "../root-selectors";
import {DashboardState} from "./types";
import {dateOnly, dayAndMonth, getDayOfWeek} from "../../helpers/dates";
import {DashboardUser, Day, GraphScore, ScoreData, User} from "../../api/types";
import {WeeklyScore} from "../../pages/Dashboard/types";

export const getIsDashboardDataValid = createSelector(
    [getDashboardState],
    (state: DashboardState): boolean => state.data !== null
)

export const getDashboardUsers = createSelector(
    [getDashboardState],
    (state: DashboardState): DashboardUser[] => state.data?.users || []
)

export const getUserName = createSelector(
    [getDashboardState],
    (state: DashboardState): string | null | undefined => state.data?.userName || '',
)

export const getUsers = createSelector(
    [getDashboardState],
    (state: DashboardState): User[] => (state.data?.users || []).map(user => user.summary)
)

export const getDays = createSelector(
    [getDashboardState],
    (state: DashboardState): Day[] => {
        if (!state.data) {
            return []
        }
        const { data: { scores }, dayRange } = state
        return scores.slice(-dayRange)
            .map(score => score.date)
            .map(date => ({
                date,
                dayName: getDayOfWeek(date, true),
                displayDate: dayAndMonth(date),
            }))
    }
)

export const getWeeklyScores = createSelector(
    [getDashboardState, getDays],
    (state: DashboardState, days): WeeklyScore[] => {
        if (!state.data) {
            return []
        }
        const { data: { scores, users } } = state
        const dates = days.map(day => dateOnly(day.date))
        const scoresInDateRange = scores.filter((score: ScoreData) => {
            return dates.includes(dateOnly(score.date))
        });
        return users.map((user: DashboardUser) => {
            return {
                user: user,
                scores: scoresInDateRange.reduce(
                    (result: { date: Date, userId: string, score: number }[], data) => {
                        const found = data.scores.find((score) => score.userId === user.summary.id);
                        if (found) {
                            result.push({ date: data.date, userId: user.summary.id, score: found.score });
                        }
                        return result;
                    },
                    []
                ),
            };
        });
    }
)

export const createGetScoresForUserSelector = createSelector(
    [getDashboardState],
    (state: DashboardState): (user: User) => GraphScore[] => user => {
        const scoresForUser: (GraphScore)[] = (state.data?.scores || []).map(data => {
            const tempDate = new Date(0)
            const scoreForUser = data.scores.find(score => score.userId === user.id)
            return scoreForUser
                ? { date: data.date, score: scoreForUser.score }
                : { date: tempDate, score: -1 }
        })
        return scoresForUser.filter(score => score.score > -1)
    }
)
