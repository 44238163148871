import {Alert} from "../../api/types";

export interface AlertsState {
    isLoading: boolean
    current: Alert[]
}

export enum CurrentAlertsAction {
    START = 'SET_CURRENT_ALERTS_START',
    SUCCESS = 'SET_CURRENT_ALERTS_SUCCESS',
    FAILURE = 'SET_CURRENT_ALERTS_FAILURE',
}
