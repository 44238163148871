import {useCallback, useEffect, useState, useRef} from "react";

export type PressKeyFnType = (key: string, duration?: number) => void
export type MouseEventSpread = {
    onMouseDown: () => void,
    onMouseUp: () => void,
}
export type KeyPressEventsFnType = (key: string, className?: string) => MouseEventSpread
export interface KeysPressedHookProps {
    keys: string[],
    areKeysPressed: (keys: string[]) => boolean
    pressKey: PressKeyFnType
    keyPressEvents: KeyPressEventsFnType
}

const useKeysPressed = (exclusiveKeys?: string[]): KeysPressedHookProps => {
    const [ _exclusiveKeys ] = useState<string[]>(exclusiveKeys || [])
    const [ keys, setKeys ] = useState<string[]>([])
    const [ pressKey, setPressKey ] = useState<PressKeyFnType>(() => () => null)
    const [ keyPressEvents, setKeyPressEvents ] = useState<KeyPressEventsFnType>(() => () => ({
        onMouseUp: () => null,
        onMouseDown: () => null,
    }))

    const areKeysPressed = useCallback((searchKeys: string[]): boolean => {
        return searchKeys.reduce((found, key) => {
            return found || keys.includes(key)
        }, false as boolean)
    }, [keys])

    const keysRef = useRef(keys)

    useEffect(() => {
        let refreshHandler: number;
        const _keys: { [id: string]: boolean } = {}
        const handleKeyboardEvent = (event: KeyboardEvent) => {
            if (_exclusiveKeys?.includes(event.code)) {
                event.preventDefault()
            }
            if (event.type === 'keydown') {
                _keys[event.code] = true
            } else {
                delete _keys[event.code]
            }
            if (Object.keys(_keys).length === 0) {
                setKeys([])
            }
        }
        const refreshKeys = () => {
            const newKeys = Object.keys(_keys)
            if (newKeys.length !== keysRef.current.length) {
                setKeys(newKeys)
                keysRef.current = newKeys
            }
            refreshHandler = window.requestAnimationFrame(refreshKeys)
        }
        refreshKeys()
        window.addEventListener('keydown', handleKeyboardEvent)
        window.addEventListener('keyup', handleKeyboardEvent)
        setPressKey(() => (key: string, duration = 100) => {
            handleKeyboardEvent({ type: 'keydown', code: key} as KeyboardEvent)
            setTimeout(() => {
                handleKeyboardEvent({ type: 'keyup', code: key} as KeyboardEvent)
            }, duration)
        })
        setKeyPressEvents(() => (key: string) => ({
            onMouseDown: () => handleKeyboardEvent(
                { type: 'keydown', code: key} as KeyboardEvent
            ),
            onMouseUp: () => handleKeyboardEvent(
                { type: 'keyup', code: key} as KeyboardEvent
            ),
        }))
        return () => {
            window.removeEventListener('keydown', handleKeyboardEvent)
            window.removeEventListener('keyup', handleKeyboardEvent)
            cancelAnimationFrame(refreshHandler)
        }
    }, [keysRef, _exclusiveKeys])
    return {
        keys,
        areKeysPressed,
        pressKey,
        keyPressEvents,
    }
}

export { useKeysPressed }
