import React, {ReactElement} from 'react'
import styles from "./ScoreTable.styles.module.scss";
import textStyles from '../../styles/_text.module.scss'
import {DayCellProps} from "./type";

const DayCell = ({ day }: DayCellProps ): ReactElement => (
    <div className={styles.dateCell} key={`${day.dayName}-${day.displayDate}`}>
        <div className={styles.dayName}>
            <span className={textStyles.bodySmall}>
                {day.dayName}
            </span>
        </div>
        <div className={styles.date}>
            <span className={textStyles.bodySmall}>
               {day.displayDate}
            </span>
        </div>
    </div>
)

export { DayCell }
