import React, {ReactElement} from 'react'
import {PageWidth} from "../../components/Layout";
import {Header} from "../../components/Header";
import {UserSearchSelector} from "../../components/UserSearchSelector";
import {SettingsProps, SettingsTabs} from "./types";

import styles from './Settings.styles.module.scss'
import {useSettingsHook} from "./Settings.hooks";
import {UserPill} from "../../components/UserSearchSelector/UserPill";
import {UserModification} from "../../components/UserSearchSelector/types";
import {ScheduleSelector} from "../../components/ScheduleSelector";

const Settings = (props: SettingsProps): ReactElement => {
    const {
        TabSelector,
        SaveConfirmModal,
        ScheduleSaveConfirmModal,
        selected,
        userSearchSelectorProps,
        scheduleSelectorProps,
        isUpdating,
    } = useSettingsHook(props)

    return (
        <PageWidth>
            <Header className={styles.allCaps} title="Settings" hideLogo={true}/>
            <TabSelector />
            <div className={styles.introduction}>
              {selected === SettingsTabs.schedule && (
                `Set the time when the users will receive the daily questions and when the managers will receive the daily and weekly report.
                All times are in UTC so if your users are in a different time zone you will need to set the time with an offset accordingly.
                (e.g to send the daily question at 11am PST you should set the daily question time to 19:00) 
                `
                )}
              {selected !== SettingsTabs.schedule && (
                `Add and remove users by searching for them. You’ll be able to add any Slack users that you’re connected with.
                Users in the ‘Current users’ list will receive a Slack notification at your scheduled time each working day asking them “Hey, how are you today?”
                `
              )}
            </div>
            <div className={styles.userSelectorContainer}>
                {isUpdating && (
                    <div>Updating...</div>
                )}
                {!isUpdating && selected === SettingsTabs.users && (
                    <UserSearchSelector {...userSearchSelectorProps} />
                )}
                {!isUpdating && selected === SettingsTabs.managers && (
                    <UserSearchSelector {...userSearchSelectorProps} />
                )}
                {!isUpdating && selected === SettingsTabs.schedule && (
                    <ScheduleSelector {...scheduleSelectorProps} />
                )}
            </div>
            <SaveConfirmModal>
                {({ addList,removeList, listName}) => (
                    <>
                        <div className={styles.pageHeading}>
                            Are you sure you want to make the following changes?
                        </div>
                        <div className={styles.list}>
                        <div className={styles.introduction}>
                            The following Slack users will be added and removed from the {listName || 'user'} list.
                        </div>
                        {addList.length > 0 && (
                            <div className={styles.list}>
                                <div className={styles.subHeading}>
                                    Adding
                                </div>
                                <div className={styles.listItems}>
                                    {addList.map(user =>
                                        <UserPill className={styles.pill} key={user.id} user={user} modification={UserModification.adding}/>
                                    )}
                                </div>
                            </div>
                        )}
                        {removeList.length > 0 && (
                            <div className={styles.list}>
                                <div className={styles.subHeading}>
                                    Removing
                                </div>
                                <div className={styles.listItems}>
                                    {removeList.map(user =>
                                        <UserPill className={styles.pill} key={user.id} user={user} modification={UserModification.removing}/>
                                    )}
                                </div>
                            </div>
                        )}
                        </div>
                    </>
                )}
            </SaveConfirmModal>
            <ScheduleSaveConfirmModal>
              {({dailyQuestionTime, dailyManagerTime, weeklyManagerTime}) => (
                <>
                  <div className={styles.pageHeading}>
                    Are you sure you want to make the following changes?
                  </div>
                  <div className={styles.list}>
                    <div className={styles.introduction}>
                      The daily question and managers reports will be scheduled for the following times.
                    </div>
                    <div className={styles.list}>
                      <div className={styles.listItems}>
                        Daily question:
                      </div>
                      <div className={styles.subHeading}>
                        {dailyQuestionTime}
                      </div>
                      <div className={styles.listItems}>
                        Daily manager report:
                      </div>
                      <div className={styles.subHeading}>
                        {dailyManagerTime}
                      </div>
                      <div className={styles.listItems}>
                        Weekly manager report:
                      </div>
                      <div className={styles.subHeading}>
                        {weeklyManagerTime}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ScheduleSaveConfirmModal>
        </PageWidth>
    )
}

export { Settings }
