import http from "./http-common";
import { AxiosResponse } from "axios";
import {ApiAlertsData, ApiDashboardData, ApiScheduleData, ApiUserData} from "./types.api";

class DashboardService {
  getDashboard(): Promise<AxiosResponse<ApiDashboardData>> {
    return http.get<ApiDashboardData>("/web/getDashboard");
  }

  getAllSlackUsers(): Promise<AxiosResponse<ApiUserData>> {
    return http.get<ApiUserData>('/web/getAllSlackUsers')
  }

  getUsers(): Promise<AxiosResponse<ApiUserData>> {
    return http.get<ApiUserData>('/web/userList')
  }

  setUsers(userIds: string[]): Promise<AxiosResponse<ApiUserData>> {
    return http.post<ApiUserData>('/web/userList', { users: userIds })
  }

  getManagers(): Promise<AxiosResponse<ApiUserData>> {
    return http.get<ApiUserData>('/web/managerList')
  }

  setManagers(userIds: string[]): Promise<AxiosResponse<ApiUserData>> {
    return http.post<ApiUserData>('/web/managerList', { users: userIds })
  }

  getCurrentAlerts(): Promise<AxiosResponse<ApiAlertsData>> {
    return http.get<ApiAlertsData>('/web/currentAlerts')
  }

  getSchedule(): Promise<AxiosResponse<ApiScheduleData>> {
    return http.get<ApiScheduleData>('/web/schedule')
  }

  setSchedule(schedule: ApiScheduleData): Promise<AxiosResponse<ApiScheduleData>> {
    return http.post<ApiScheduleData>('/web/schedule', schedule)
  }
}

export default new DashboardService();
