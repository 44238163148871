import {connect} from "react-redux";
import { App } from "./App";
import {RootState} from "./redux/types";
import {ConfigState} from "./redux/config/types";
import {getIsOnboarding} from "./redux/config/config.selectors";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {stopOnboarding} from "./redux/config/config.actions";

const mapStateToProps = (state: RootState): ConfigState => ({
    isOnboarding: getIsOnboarding(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch<ConfigState, null, Action>) => ({
    doStopOnboarding: () => dispatch(stopOnboarding()),
})

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App)

export { AppContainer }
