import React, {FC} from "react";
import styles from "../../WelcomeModal.styles.module.scss";
import onboardingImage from './onboardingThreeImage.jpg';

const OnboardingThree: FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.pageHeading}>
                Manage your team
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.onboardingBody}>
                        Team Members and Managers can be added right here on the HappyTrack dashboard.
                        <br></br>
                        <br></br>
                        Use the /happytrack help command directly in Slack to see a list of useful commands.
                        <br></br>
                        <br></br>
                        Go to the Settings tab to start adding members.
                    </div>
                    <div className={styles.image}>
                        <img src={onboardingImage} alt="Onboarding image"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { OnboardingThree }