import {Day, Score, DashboardUser} from "../../api/types";
import {Cell, CellType} from "./type";
import {Style} from '../../styles/types';

export interface KeyedDates {
    [id: number]: Date
}

const createUserCell =
    (user: DashboardUser): Cell => ({ type: CellType.user, content: user })
const createBlankCell = (): Cell => ({ type: CellType.blank } as Cell)
const createEmptyCell = (): Cell => ({ type: CellType.empty } as Cell)
const createPendingCell = (): Cell => ({ type: CellType.pending } as Cell)
const createDayCell = (day: Day): Cell => ({type: CellType.date, content: day})
const createScoreCell = (score: Score, style: Style): Cell => ({
    type: CellType.score,
    content: score,
    style,
})

export {
    createUserCell,
    createBlankCell,
    createDayCell,
    createScoreCell,
    createEmptyCell,
    createPendingCell,
}
