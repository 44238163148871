import { useMemo } from "react";
import {UserScoreGraphHookProps, UserScoreGraphProps} from "./types";

const useUserScoreGraphHook = (props: UserScoreGraphProps): UserScoreGraphHookProps => {
    const { user, getScoresForUser } = props
    const scores = useMemo(() => getScoresForUser(user.summary), [user, getScoresForUser])
    return {
        ...props,
        scores,
    }
}

export { useUserScoreGraphHook }
