import {DashboardState} from "./dashboard/types";
import {NavigationState} from "./navigation/types";
import {AlertsState} from "./alerts/types";
import {ConfigState} from "./config/types";

export interface RootState {
    dashboard: DashboardState
    navigation: NavigationState
    alerts: AlertsState
    config: ConfigState
}

export enum RootAction {
    REDIRECT = 'SET_REDIRECT'
}
