import React, {ReactElement} from 'react'
import {ScoreTableProps} from "./type";
import styles from './ScoreTable.styles.module.scss'
import {useScoreTableHook} from "./ScoreTable.hook";
import {ScoreTableRow} from "./ScoreTableRow";

const ScoreTable = (props: ScoreTableProps): ReactElement => {
    const {
        scoreGrid,
    } = useScoreTableHook(props)
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                Team Daily Scores
            </div>
            <div className={styles.subHeading}>
                Click on individual users for a more detailed analysis
            </div>
            {scoreGrid.map(((row, rowIndex) => (
                <ScoreTableRow row={row} key={`row_${rowIndex}`} />
            )))}
        </div>
    )
}

export { ScoreTable }
