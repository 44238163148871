import {AxiosResponse} from "axios";
import {ApiScheduleData} from "../../api/types.api";

export interface ScheduleSelectorProps {
  className?: string
  scheduleSource: () => Promise<AxiosResponse<ApiScheduleData>>
  onSelectionChanged?: (schedule?: ApiScheduleData) => void
  handleSubmitChanges?: (schedule: ApiScheduleData) => void
}

export interface ScheduleSelectorHookProps extends ScheduleSelectorProps {
  isLoading: boolean
  showSave: boolean
  newSchedule: ApiScheduleData
  dailyQuestionTime: string
  dailyManagerReportTime: string
  weeklyManagerReportTime: string
  handleScheduleModification: (scheduleType: ScheduleType, time: string) => void
}

export enum ScheduleType {
  dailyQuestion,
  dailyManagerReport,
  weeklyManagerReport,
}