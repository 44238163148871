import React, {ReactElement} from 'react'
import styles from "./Header.styles.module.scss"
import textStyles from '../../styles/_text.module.scss'
import {HappyTrackIcon} from "../../icons/happyTrack";
import {HeaderProps} from "./types";

const Header = (
    {
        title = 'HappyTrack',
        hideLogo,
        className,
    }: HeaderProps
): ReactElement => (
    <div className={`
        ${styles.header}
        ${className ? className : ''}
    `}>
        {hideLogo !== true && <HappyTrackIcon />}
        <div className={styles.title}>
            <span className={textStyles.pageHeading}>
                {title}
            </span>
        </div>
    </div>
)

export { Header }
