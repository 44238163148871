import {useMemo} from 'react'
import {PillButton, UserPillHookProps, UserPillProps} from "./types";
import {ModifyAction, UserModification} from "../types";
import {getActionForUser} from "../UserSearchSelector.helpers";

const useUserPillHook = (props: UserPillProps): UserPillHookProps => {
    const { modification } = props
    const action = getActionForUser(props)
    const button = useMemo(() => {
        switch (true) {
            case !modification && action === UserModification.removing:
                return PillButton.minus
            case !modification && action === UserModification.adding:
                return PillButton.add
            case action === modification:
            case action === ModifyAction.reverse:
                return PillButton.close
            default:
                return PillButton.none
        }
    }, [modification, action])
    return {
        ...props,
        button,
        action,
    }
}

export { useUserPillHook }
