import React, {ReactElement} from 'react'
import styles from './ScoreTable.styles.module.scss'
import textStyles from '../../styles/_text.module.scss'
import {ScoreCellProps} from "./type";

const ScoreCell = ( { score, style }: ScoreCellProps): ReactElement => (
    <div className={styles.scoreCell} key={`${score.date.getDate()}`} style={style}>
        <span className={textStyles.body}>
            {score.score}
        </span>
    </div>
)

export { ScoreCell }
