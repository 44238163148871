import React, {ComponentType, PropsWithChildren, ReactElement, useState} from 'react'
import {ModalProps, OptionalModalProps} from './types'
import colors from '../../styles/_colors.module.scss';
import styles from './Modal.styles.module.scss'
import { useModalHooks } from './Modal.hook'
import {CloseIcon} from "../../icons/close";

const withModal = <WrappedComponentType, >(
    WrappedComponent: ComponentType<WrappedComponentType>,
    options?: OptionalModalProps,
): ComponentType<WrappedComponentType> => {
    const ComponentWithModal = (
        {
            children,
            ...otherProps
        }: PropsWithChildren<WrappedComponentType>) => {
        const [isOpen, setIsOpen] = useState<boolean>(false)
        return (
            <>
                <div
                    className={styles.modalTrigger}
                    onClick={() => setIsOpen(true)}
                >
                    {children}
                </div>
                <Modal {...{
                    ...(options ? options: {}),
                    isOpen,
                    onClose: () => setIsOpen(false),
                }}>
                    <WrappedComponent {...(otherProps as WrappedComponentType)} />
                </Modal>
            </>
        )
    }
    return ComponentWithModal
}

const Modal = (props: ModalProps): ReactElement | null => {
    const {
        onClose,
        shouldRenderContent,
        isClosing,
        children,
        smallerModal,
        autoSize,
        hideCloseButton,
    } = useModalHooks(props)
    const containerClass = `${styles.container} ${isClosing ? styles.close : styles.open}`
    const fixedSize = smallerModal ? styles.smallerModal : styles.modal
    return shouldRenderContent ? (
            <div className={containerClass}>
                <div className={styles.background} onClick={onClose} />
                <div className={autoSize ? styles.autoSize : fixedSize}>
                    <div>{children}</div>
                    {!hideCloseButton && (
                        <button className={styles.closeButton} onClick={onClose}>
                            <CloseIcon size={32} color={colors.text} />
                        </button>
                    )}
                </div>
            </div>
    ) : null
}

export { withModal, Modal }
