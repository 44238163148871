import {RootState} from "../../redux/types";
import {getUsers} from "../../redux/dashboard/dashboard.selectors";
import {connect} from "react-redux";
import {CurrentAlertsStateProps} from "./types";
import {CurrentAlerts} from "./CurrentAlerts";
import {getCurrentAlerts} from "../../redux/alerts/alerts.selectors";

const mapStateToProps = (state: RootState): CurrentAlertsStateProps => ({
    users: getUsers(state),
    alerts: getCurrentAlerts(state),
})

const CurrentAlertsContainer = connect(
    mapStateToProps,
)(CurrentAlerts)

export { CurrentAlertsContainer }
