import {AverageScoreGraphHookProps, AverageScoreGraphProps, ScoresByDate} from "./types";
import {GraphScore} from "../../api/types";
import {dayAndMonth} from "../../helpers/dates";

const useAverageScoreGraphHook = (props: AverageScoreGraphProps): AverageScoreGraphHookProps => {
    const { weeklyScores } = props
    const scoresByDate: { [date: string]: ScoresByDate } = weeklyScores.reduce<{ [date: string]: ScoresByDate }>(
        (allDates, weekly) =>
            weekly.scores.reduce<{ [date: string]: ScoresByDate }>((dates, score) => {
                    const dateKey = dayAndMonth(score.date)
                    const currentScoreForDateKey = allDates[dateKey]?.score || 0
                    const currentEntriesForDateKey = allDates[dateKey]?.entries || 0
                    return {
                        ...allDates,
                        ...dates,
                        [dateKey]: {
                            date: score.date,
                            entries: currentEntriesForDateKey + 1,
                            score: currentScoreForDateKey + score.score,
                        }
                    }
                }, {})
        , {})
    const scores: GraphScore[] = Object.keys(scoresByDate).map(key => {
        const date = scoresByDate[key]
        return {
            date: date.date,
            score: Math.round((date.score / date.entries) * 10) / 10
        }
    })
    scores.sort((a: GraphScore, b: GraphScore) =>
        a.date.getTime() - b.date.getTime()
    );
    return {
        ...props,
        scores,
    }
}

export { useAverageScoreGraphHook }
