import {SearchableInputHookProps, SearchableInputProps} from "./types";
import {useState, useEffect} from "react";
import {useSearch} from "./Search.hook";
import {useKeysPressed} from "./KeysPressed.hook";

const useSearchableInputHook = <SearchType>(
    props: SearchableInputProps<SearchType>
): SearchableInputHookProps<SearchType> => {
    const {
        fetchSearchData,
        processSearchData,
        searchableString,
        allowKeyboardSelection,
        preselectFirstResult,
        onKeyboardSelection,
    } = props
    const search = useSearch<SearchType>({
        fetchSearchData,
        processSearchData,
        searchableString,
    })
    const [selectedIndex, setSelectedIndex] = useState<number>(preselectFirstResult ? 0 : -1)
    const [moveSelectedIndex, setMoveSelectedIndex] = useState<number>(0)
    const { areKeysPressed } = useKeysPressed(['ArrowDown', 'ArrowUp', 'Enter'])

    useEffect(() => {
        if (!allowKeyboardSelection || !search.searchResults.length) {
            return
        }
        if (
            areKeysPressed(['Enter'])
            && selectedIndex > -1
            && search.searchResults[selectedIndex]
            && typeof onKeyboardSelection === 'function'
            ) {
                onKeyboardSelection({
                    result: search.searchResults[selectedIndex],
                    clearSearch: search.clearSearch,
                    isSelected: true
                })
        } else if (areKeysPressed(['ArrowDown'])) {
            setMoveSelectedIndex(1)
        } else if (areKeysPressed(['ArrowUp'])) {
            setMoveSelectedIndex(-1)
        } else if (areKeysPressed(['Escape'])) {
            search.clearSearch()
        }
    }, [areKeysPressed, setMoveSelectedIndex, search])

    useEffect(() => {
        const newIndex = selectedIndex + moveSelectedIndex
        setMoveSelectedIndex(0)
        if (newIndex !== selectedIndex && search.searchResults.length) {
            if (newIndex < 0) {
                setSelectedIndex(search.searchResults.length -1)
            } else {
                setSelectedIndex(newIndex % search.searchResults.length)
            }
        }
    }, [selectedIndex, moveSelectedIndex])

    useEffect(() => {
        setSelectedIndex(preselectFirstResult ? 0 : -1)
    }, [search.searchResults])

    return {
        ...props,
        ...search,
        selectedIndex: allowKeyboardSelection ? selectedIndex : -1,
    }
}

export { useSearchableInputHook }
