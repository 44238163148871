import React, {ReactElement, useMemo} from 'react'
import Moment from 'moment'

export interface TimePickerProps {
  defaultValue: string,
  onChange: (value: string) => void,
  name: string,
  beginLimit?: string,
  endLimit?: string,
  step?: number
}

const TimePicker = (props: TimePickerProps): ReactElement => {
  const {
    defaultValue,
    onChange,
    name,
    beginLimit,
    endLimit,
    step,
  } = props

  const isEarlierThanEndLimit = function(timeValue: string, endLimit: string , lastValue?: string) {
    const timeValueIsEarlier = Moment(timeValue, 'HH:mm').diff(Moment(endLimit, 'HH:mm')) < 0
    const timeValueIsLaterThanLastValue = lastValue === undefined ? true : Moment(lastValue, 'HH:mm').diff(Moment(timeValue, 'HH:mm')) < 0
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  }

  const options = useMemo(() => {
    let timeValue = beginLimit || "00:00"
    let lastValue: string | undefined
    const endValue = endLimit || "23:00";
    const stepValue = step || 15;

    const optionArray = [];
    optionArray.push(<option key={timeValue} value={timeValue}>{timeValue}</option>);

    while (isEarlierThanEndLimit(timeValue, endValue, lastValue) ) {
      lastValue = timeValue;
      timeValue = Moment(timeValue, 'HH:mm').add(stepValue, 'minutes').format('HH:mm');
      optionArray.push(<option key={timeValue} value={timeValue}>{timeValue}</option>)
    }
    return optionArray
  }, [beginLimit, endLimit, step, isEarlierThanEndLimit])

  const selectorChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value)
  }

  return (
    <select defaultValue={defaultValue} onChange={selectorChanged} name={name}>
      {options}
    </select>
  )
}

export { TimePicker }
