import React, {ReactElement} from 'react'
import styles from './NavigationBar.styles.module.scss'
import sizes from '../../styles/_sizes.module.scss'
import {DashboardIcon} from "../../icons/dashboard";
import {ConfigIcon} from "../../icons/config";
import {NavigationBarProps} from "./types";
import {NavigationItem} from "./index";
import {Endpoint} from "../../pages/types";

const NavigationBar = (props: NavigationBarProps): ReactElement => {
    const width = props.width || 70
    return (
        <>
            <style>
                {`
                    body {
                        margin-left: ${width}px !important;
                    }
            
                    @media (max-width: ${Number(sizes.hiResDesktopMin) + width + (Number(sizes.padding) * 2) }px) {
                        .fullWidthLayout {
                            align-items: unset !important;
                        }
                
                        .pageWidthLayout {
                            width: unset !important;
                            max-width: unset !important;
                        }
                    }
                `}
            </style>
            <div className={styles.navigationBar} style={{ width }}>
                <NavigationItem
                    className={styles.item}
                    endpoint={Endpoint.dashboard}
                    icon={DashboardIcon}
                />
                <NavigationItem
                    className={styles.item}
                    endpoint={Endpoint.settings}
                    icon={ConfigIcon}
                />
                <div className={styles.spacer}/>
                <div className={styles.item} onClick={props.doStartOnboarding}>
                    <div className={styles.help}>?</div>
                </div>
            </div>
        </>
    )
}

export { NavigationBar }
