import {NavigationAction, NavigationState} from "./types";
import {AnyAction} from "redux";

const INITIAL_STATE: NavigationState = {
    challengeNavigation: null,
}

const navigationReducer = (state = INITIAL_STATE, action: AnyAction): NavigationState => {
    switch (action.type) {
        case NavigationAction.SET_CHALLENGE:
            return {
                ...state,
                challengeNavigation: action.payload,
            }
        case NavigationAction.CLEAR_CHALLENGE:
            return {
                ...state,
                challengeNavigation: null,
            }
        default:
            return state
    }
}

export { navigationReducer }
