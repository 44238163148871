import {ConfigAction, ConfigState} from "./types";
import {AnyAction} from "redux";

const INITIAL_STATE: ConfigState = {
    isOnboarding: true,
}

const configReducer = (state = INITIAL_STATE, action: AnyAction): ConfigState => {
    switch (action.type) {
        case ConfigAction.START_ONBOARDING:
            return {
                ...state,
                isOnboarding: true
            }
        case ConfigAction.STOP_ONBOARDING:
            return {
                ...state,
                isOnboarding: false,
            }
        default:
            return state
    }
}

export { configReducer }
