import React, {ReactElement} from 'react'
import colors from '../../styles/_colors.module.scss'
import {IconProps} from "../types";

const DashboardIcon = ({
    size = 33,
    color = colors.pageBackground,
    inheritFill,
}: IconProps): ReactElement => {
    const fillStyle = inheritFill ? {} : { fill: color }
    return (
        <svg width={size} height={size} viewBox="0 -1.5 32 32">
            <g fillRule="evenodd">
                <g {...fillStyle}>
                    <path d="M32 49c.552 0 1 .448 1 1v27c0 .552-.448 1-1 1H20c-.552 0-1-.448-1-1V50c0-.552.448-1 1-1h12zm18 13c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H37c-.552 0-1-.448-1-1V63c0-.552.448-1 1-1h13zm0-13c.552 0 1 .448 1 1v8c0 .552-.448 1-1 1H37c-.552 0-1-.448-1-1v-8c0-.552.448-1 1-1h13z" transform="translate(-19 -49)"/>
                </g>
            </g>
        </svg>
    )
}

export { DashboardIcon }
