import React, {ReactElement, useState, useCallback} from 'react'
import {WelcomeModalProps} from "./type";
import styles from './WelcomeModal.styles.module.scss'
import {InitialScreen} from "./sections/initialScreen/initialScreen";
import {OnboardingOne} from "./sections/onboardingOne/onboardingOne";
import {OnboardingTwo} from "./sections/onboardingTwo/onboardingTwo";
import {OnboardingThree} from "./sections/onboardingThree/onboardingThree";
import {NavigationItem} from "../NavigationBar";
import { CloseIcon } from "../../icons/close";
import colors from "../../styles/_colors.module.scss";
import {Endpoint} from "../../pages/types";

const WelcomeModal = (
    {
        userName,
        doStopOnboarding,
    }: WelcomeModalProps
): ReactElement => {
    const [section, setSection] = useState<number>(0)
    const handleNextSection = useCallback(() => {
        setSection(section + 1)
    }, [section, setSection])
    const roundButtonStyle = useCallback(index => {
        return `${styles.roundButtons} ${
            section === index ? styles.selected : ''
        }`
    }, [section])
    return (
        <div>
            {section === 0 && <InitialScreen
                userName={userName}
                onClickNextSection={handleNextSection}
                onClickClose={doStopOnboarding}
            />}
            {section === 1 && <OnboardingOne/>}
            {section === 2 && <OnboardingTwo/>}
            {section === 3 && <OnboardingThree/>}
            {section > 0 && (
            <div className={styles.actionsContainer}>
                <div className={styles.actionButtons}/>
                <div className={styles.sectionButtons}>
                    <button className={roundButtonStyle(1)} onClick={() => setSection(1)}> </button>
                    <button className={roundButtonStyle(2)} onClick={() => setSection(2)}> </button>
                    <button className={roundButtonStyle(3)} onClick={() => setSection(3)}> </button>
                    <button className={styles.closeButton} onClick={doStopOnboarding}>
                        <CloseIcon size={32} color={colors.text}/>
                    </button>
                </div>
                <div className={styles.actionButtons}>
                    {section < 3 && (
                        <button className={styles.nextButton} onClick={handleNextSection}>Next</button>
                    )}
                    {section === 3 && (
                        <NavigationItem
                            className={styles.settingsButton}
                            endpoint={Endpoint.settings}
                            name="Go to Settings"
                            beforeNavigation={() => doStopOnboarding()}
                        />
                    )}
                </div>
            </div>
            )}
        </div>
    )
}

export { WelcomeModal }
