import {
    NavigationItemHookProps,
    NavigationItemProps,
} from "./types";
import {useCallback, useMemo} from "react";
import {useConfirmModal} from "../ConfirmModal";

const useNavigationItemHook = (props: NavigationItemProps): NavigationItemHookProps => {
    const {
        location: { pathname },
        endpoint,
        history,
        challengeNavigation,
        doClearNavigationChallenge,
        beforeNavigation
    } = props
    const isHighlighted = useMemo(() => {
        return pathname.startsWith(endpoint)
    }, [pathname])
    const [ConfirmModal, openConfirmModal] = useConfirmModal({})
    const handleNavigation = useCallback((destination: string) => () => {
        if (challengeNavigation) {
            openConfirmModal(challengeNavigation)
                .then(() => {
                    doClearNavigationChallenge()
                    beforeNavigation?.()
                    history.push(destination)
                }, () => null)
        } else {
            if (beforeNavigation) {
                beforeNavigation()
            }
            history.push(destination)
        }
    }, [challengeNavigation])
    return {
        ...props,
        ConfirmModal,
        isHighlighted,
        handleNavigation,
    }
}

export { useNavigationItemHook }
