import React, { FC } from "react";

// TODO: Consider removing this page entirely
// TODO URL is based on the GCP App Engine service so will need changing to match the project and service
const Login: FC = () => (
    <div className="login-wrapper">
      <a href="https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.team&client_id=2722079505.1153914092628&redirect_uri=https%3A%2F%2Fhome-dot-happytrack.nw.r.appspot.com%2Fapi%2Fauth%2FsignInWithSlackOauthCallback">
        <img
          alt="Sign in with Slack"
          height="40"
          width="172"
          src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
          srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
        />
      </a>
    </div>
  )

export default Login
