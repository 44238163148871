import {UseTabSelectorInputProps, UseTabSelectorOutput} from "./types";
import React, {useCallback, useState} from "react";
import {TabSelector} from "./index";

const useTabSelector = (
    {
        tabs,
        shouldChangeTab,
        bindToNavigationChallenge,
    }: UseTabSelectorInputProps
): UseTabSelectorOutput => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const handleSelectionChange = useCallback(index => {
        if (typeof shouldChangeTab === 'function') {
            const result = shouldChangeTab(tabs[selectedIndex], tabs[index])
            if (result instanceof Promise) {
                result.then(() => setSelectedIndex(index))
            } else if (result) {
                setSelectedIndex(index)
            }
        } else if (typeof shouldChangeTab === 'undefined') {
            setSelectedIndex(index)
        }
    }, [shouldChangeTab])
    return [
        () => <TabSelector
            tabs={tabs}
            selection={selectedIndex}
            bindToNavigationChallenge={bindToNavigationChallenge}
            onSelectionChange={handleSelectionChange}
        />,
        tabs[selectedIndex],
    ]
}

export { useTabSelector }
