import React, {ReactElement} from "react";
import {UserScoreGraphProps} from "./types";
import styles from './UserScoreGraph.styles.module.scss'
import {useUserScoreGraphHook} from "./UserScoreGraph.hooks";
import {ScoreGraph} from "../ScoreGraph";

const UserScoreGraph = (props: UserScoreGraphProps): ReactElement => {
    const {
        user,
        scores,
    } = useUserScoreGraphHook(props)
    return (
        <div className={styles.container}>
            <div className={styles.graphHeading}>
                Scores for {user.summary.displayName}
            </div>
            {scores.length > 0 && (
              <ScoreGraph scores={scores}/>
            )}
            {scores.length == 0 && (
              <div>No scores to display</div>
            )}
        </div>
    )
}

export { UserScoreGraph }
