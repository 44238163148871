import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {startOnboarding} from "../../redux/config/config.actions";
import {ConfigState} from "../../redux/config/types";
import {connect} from "react-redux";

import {NavigationBar} from "./NavigationBar";

const mapDispatchToProps = (dispatch: ThunkDispatch<ConfigState, null, Action>) => ({
    doStartOnboarding: () => dispatch(startOnboarding()),
})

const NavigationBarContainer = connect(null, mapDispatchToProps)(NavigationBar)

export { NavigationBarContainer }
