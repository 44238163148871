import {RootState} from "../../redux/types";
import {NavigationStateProps} from "../NavigationBar/types";
import {getNavigationChallenge} from "../../redux/navigation/navigation.selectors";
import {ThunkDispatch} from "redux-thunk";
import {NavigationState} from "../../redux/navigation/types";
import {Action} from "redux";
import {clearNavigationChallenge} from "../../redux/navigation/navigation.actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {TabSelector} from "./TabSelector";

const mapStateToProps = (state: RootState): NavigationStateProps => ({
    challengeNavigation: getNavigationChallenge(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch<NavigationState, null, Action>) => ({
    doClearNavigationChallenge: () => dispatch(clearNavigationChallenge())
})

const TabSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(TabSelector))

export { TabSelectorContainer }
