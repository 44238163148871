import React, {ReactElement} from "react";
import styles from "./initialScreen.styles.module.scss";
import initialScreenImage from './initialScreenImage.jpg';
import {WelcomeModalScreenProps} from "../../type";

const InitialScreen = (
    props: WelcomeModalScreenProps,
): ReactElement => {
    return (
        <div className={styles.container}>
            <div className={styles.image}>
                <img src={initialScreenImage} alt="Initial screen image"/>
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.name}>
                        Hi {props.userName || 'user'}
                    </div>
                    <div className={styles.emoji}>
                        🎉
                    </div>
                    <div className={styles.welcome}>
                        Welcome to HappyTrack
                        <br></br>
                        Let’s get you set up!
                    </div>
                </div>
            </div>
            <div className={styles.wrapButton}>
                <button
                    className={styles.startedButton}
                    onClick={props.onClickNextSection}
                >
                    Get started
                </button>
            </div>
            <div className={styles.wrapButton}>
                <button
                    className={styles.notRightNowButton}
                    onClick={props.onClickClose}
                >
                    Not right now
                </button>
            </div>
        </div>
    )
}

export { InitialScreen }
