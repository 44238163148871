import {
    Cell,
    ScoreTableHookProps,
    ScoreTableProps,
} from "./type";
import {useCallback, useMemo} from "react";
import {getContrastingTextColor, getInterpolatedColor} from "../../helpers/colors";
import {
    createBlankCell,
    createDayCell,
    createEmptyCell,
    createPendingCell,
    createScoreCell,
    createUserCell,
} from "./ScoreTable.helpers";
import {Day, Score} from "../../api/types";
import {WeeklyScore} from "../../pages/Dashboard/types";
import {Style} from '../../styles/types';
import {dateOnly} from "../../helpers/dates";

const useScoreTableHook = (props: ScoreTableProps): ScoreTableHookProps => {
    const {
        days,
        colorRange,
        weeklyScores,
    } = props

    const colorStyleForScoreBlock = useCallback((score: Score): Style => {
        const color = getInterpolatedColor(colorRange, score.score, 1, 10)
        return {
            color: getContrastingTextColor(color),
            backgroundColor: color,
        }
    }, [colorRange])

    const scoreGrid = useMemo((): Cell[][] => {
        const dateHeader = [
            createBlankCell(),
            ...days.map(day => createDayCell(day)),
        ]

        const createScoreCellsForRow = (row: WeeklyScore) => (day: Day) => {
            const today = dateOnly(new Date())
            const isToday = dateOnly(day.date) === today
            if (isToday && !row.user.details.hasQuestionBeenSentToday) {
                return createPendingCell()
            }
            const score = row.scores.find(score =>
                dateOnly(day.date) === dateOnly(score.date)
            )
            return score
                ? createScoreCell(score, colorStyleForScoreBlock(score))
                : createEmptyCell()
        }

        const createUserRow = (row: WeeklyScore) => ([
            createUserCell(row.user),
            ...days.map(createScoreCellsForRow(row)),
        ])

        return [
            dateHeader,
            ...weeklyScores.map(createUserRow),
        ]
    }, [days])

    return {
        ...props,
        scoreGrid,
    }
}

export { useScoreTableHook }
