import {useEffect, useMemo} from "react";
import {DashboardHookProps, DashboardProps} from "./types";
import {monthlyAverage} from "./Dashboard.helpers";

const useDashboardHook = (props: DashboardProps): DashboardHookProps => {
    const { data, doStartDashboard, doGetCurrentAlerts } = props
    const scores = data?.scores || []
    useEffect(() => {
        doStartDashboard()
        doGetCurrentAlerts()
    }, [doStartDashboard, doGetCurrentAlerts])

    const monthlyScores = useMemo(
        () => monthlyAverage(scores),
    [scores]
    )

    return {
        ...props,
        monthlyScores,
    }
}

export { useDashboardHook }
