import {ThunkDispatch} from "redux-thunk";
import {NavigationState} from "../../redux/navigation/types";
import {Action} from "redux";
import {
    clearNavigationChallenge,
    setNavigationChallenge
} from "../../redux/navigation/navigation.actions";
import {connect} from "react-redux";
import {Settings} from './Settings';
import {withRouter} from "react-router";
import {ConfirmationMessage} from "../../api/types";

const mapDispatchToProps = (dispatch: ThunkDispatch<NavigationState, null, Action>) => ({
    doSetNavigationChallenge: (challenge: ConfirmationMessage) => dispatch(setNavigationChallenge(challenge)),
    doClearNavigationChallenge: () => dispatch(clearNavigationChallenge())
})

const SettingsContainer = connect(null, mapDispatchToProps)(withRouter(Settings))

export { SettingsContainer }
