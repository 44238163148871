import {ScheduleSelectorHookProps, ScheduleSelectorProps, ScheduleType} from "./types";
import {ApiScheduleData} from "../../api/types.api";
import {useCallback, useEffect, useMemo, useState} from "react";
import Moment from "moment";

const useScheduleSelectorHook = (props: ScheduleSelectorProps): ScheduleSelectorHookProps => {
  const {
    scheduleSource,
    onSelectionChanged,
  } = props
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ schedule, setSchedule] = useState<ApiScheduleData>({dailyQuestionHour: 0, dailyManagerReportHour: 0, weeklyManagerReportHour: 0})
  const [ newSchedule, setNewSchedule] = useState<ApiScheduleData>({dailyQuestionHour: 0, dailyManagerReportHour: 0, weeklyManagerReportHour: 0})

  useEffect(() => {
    scheduleSource()
      .then(response => {
        setSchedule(response.data)
        setNewSchedule(response.data)
      })
      .then(() => setIsLoading(false))
  }, [scheduleSource])

  useEffect(() => onSelectionChanged?.(newSchedule), [newSchedule])

  const handleScheduleModification = useCallback((scheduleType: ScheduleType, time: string) => {
    const changedSchedule: ApiScheduleData = {...newSchedule}
    const timeValue = Moment(time, 'HH:mm')
    const hour = timeValue.get('hours')
    switch (scheduleType) {
      case ScheduleType.dailyQuestion:
        changedSchedule.dailyQuestionHour = hour
        break
      case ScheduleType.dailyManagerReport:
        changedSchedule.dailyManagerReportHour = hour
        break
      case ScheduleType.weeklyManagerReport:
        changedSchedule.weeklyManagerReportHour = hour
        break
      default:
        break
    }
    setNewSchedule(changedSchedule)
  },[schedule, newSchedule, setNewSchedule])

  const showSave = useMemo(() => {
    return schedule.dailyQuestionHour != newSchedule.dailyQuestionHour ||
      schedule.dailyManagerReportHour != newSchedule.dailyManagerReportHour ||
      schedule.weeklyManagerReportHour != newSchedule.weeklyManagerReportHour
  }, [schedule, newSchedule])

  const dailyQuestionTime = useMemo(() => {
    const time = Moment(`${newSchedule.dailyQuestionHour}:00`, 'H:mm')
    return time.format("HH:mm")
  }, [newSchedule])

  const dailyManagerReportTime = useMemo(() => {
    const time = Moment(`${newSchedule.dailyManagerReportHour}:00`, 'H:mm')
    return time.format("HH:mm")
  }, [newSchedule])

  const weeklyManagerReportTime = useMemo(() => {
    const time = Moment(`${newSchedule.weeklyManagerReportHour}:00`, 'H:mm')
    return time.format("HH:mm")
  }, [newSchedule])

  return {
    ...props,
    isLoading,
    showSave,
    newSchedule,
    dailyQuestionTime,
    dailyManagerReportTime,
    weeklyManagerReportTime,
    handleScheduleModification
  }
}

export { useScheduleSelectorHook }