import {WeeklyScore} from "../../pages/Dashboard/types";
import {DashboardUser, Score, Day} from "../../api/types";
import {Style} from '../../styles/types';

export enum CellType {
    blank,
    score,
    date,
    user,
    empty,
    pending,
}

export interface Cell {
    type: CellType,
    content: Score | Day | DashboardUser | undefined,
    style?: Style
}

export interface ScoreCellProps {
    score: Score
    style?: Style
}

export interface DayCellProps {
    day: Day
}

export interface UserCellProps {
    user: DashboardUser
}

export interface ScoreTableProps {
    days: Day[]
    colorRange: string[]
    weeklyScores: WeeklyScore[]
}

export interface ScoreTableRowProps {
    row: Cell[]
}

export interface ScoreTableHookProps extends ScoreTableProps {
    scoreGrid: Cell[][]
}
