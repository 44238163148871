import React, {ReactElement} from "react";
import styles from "./NavigationBar.styles.module.scss";
import {NavigationItemProps} from "./types";
import {useNavigationItemHook} from "./NavigationItem.hook";

const NavigationItem = (props: NavigationItemProps): ReactElement => {
    const {
        className,
        icon: Icon,
        name,
        endpoint,
        isHighlighted,
        handleNavigation,
        ConfirmModal,
    } = useNavigationItemHook(props)
    return (
        <>
            <div className={`
                    ${className}
                    ${isHighlighted && styles.highlighted}
                `}
                 onClick={handleNavigation(endpoint)}
            >
                {Icon && <Icon inheritFill={true}/>}
                {name && <div>{name}</div>}
            </div>
            <ConfirmModal/>
        </>
    )
}

export { NavigationItem }
