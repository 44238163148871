export enum Size {
    quarter = '25%',
    third = '33.3%',
    half = '50%',
    twoThirds = '66.6%',
    full = '100%',
}

export interface LayoutProps {
    className?: string
    size?: Size,
}
