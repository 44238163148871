import React, { ReactElement } from 'react'
import colors from '../../styles/_colors.module.scss'
import {IconProps} from "../types";

const CloseIcon = (
    {
        size = 24,
        inheritFill,
        color = colors.text,
    }: IconProps
): ReactElement => {
    const strokeWidth = 5
    const strokeStyle = {
        strokeWidth,
        fill: 'none',
        ...inheritFill
            ? {}
            : {stroke: color},
    }
    return (
        <svg width={size} height={size} viewBox="0 0 50 50">
            <g transform="rotate(45) translate(11, -24)">
                <line
                    x1={strokeWidth}
                    x2={50 - strokeWidth}
                    y1={25}
                    y2={25}
                    strokeLinecap={'round'}
                    {...strokeStyle}
                />
                <line
                    x1={25}
                    x2={25}
                    y1={strokeWidth}
                    y2={50 - strokeWidth}
                    strokeLinecap={'round'}
                    {...strokeStyle}
                />
            </g>
        </svg>
    )
}

export { CloseIcon }
