import { User } from '../../api/types'
import {ModifiedUser, ModifyAction, UserModification} from './types'

const mapUserToModifiedUser = (modification?: UserModification, isCurrent?: boolean) =>
    (user: User): ModifiedUser => ({
        user,
        modification,
        isCurrent,
    })

const mapUserToExistingModifiedUser = (existingUsers: ModifiedUser[]) => (user: User): ModifiedUser => ({
    user,
    modification: existingUsers.find(modifiedUser => modifiedUser.user.id == user.id)?.modification,
    isCurrent: true,
})

const mapModifiedUsersByMergingExistingModifiedUsers = (existingUsers: ModifiedUser[]) => (user: ModifiedUser): ModifiedUser =>
    existingUsers.find(modifiedUser => modifiedUser.user.id == user.user.id) || user

const sortAddedUsersFirst = (user: ModifiedUser): number =>
    user.modification === UserModification.adding ? -1 : 0

const sortUnModifiedUsersFirst = (user: ModifiedUser): number =>
    !user.modification ? -1 : 0

const getActionForUser = (user: ModifiedUser): UserModification | ModifyAction => {
    const addModification = user.isCurrent
        ? UserModification.removing
        : UserModification.adding
    return user.modification ? ModifyAction.reverse : addModification
}

export {
    mapUserToModifiedUser,
    mapUserToExistingModifiedUser,
    mapModifiedUsersByMergingExistingModifiedUsers,
    sortAddedUsersFirst,
    sortUnModifiedUsersFirst,
    getActionForUser,
}
