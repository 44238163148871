import {AlertListItem, CurrentAlertsHookProps, CurrentAlertsProps} from "./types";
import {useMemo} from "react";
import {User} from "../../api/types";

const useCurrentAlertsHook = (props: CurrentAlertsProps): CurrentAlertsHookProps => {
    const {
        users,
        alerts,
    } = props

    const alertsList = useMemo((): AlertListItem[] => {
        return alerts.map(alert => ({
            user: users.find(user => user.id === alert.userId) || {} as User,
            alerts: [
                ...(alert.lowerThanAverageAlert && !alert.lowerQuartileAlert ? [{
                    text: 'Lower than usual score',
                    style: 'lowerThanUsual',
                }] : []),
                ...(alert.usuallyAnsweredAlert ? [{
                    text: 'Did not respond',
                    style: 'didNotRespond',
                }] : []),
                ...(alert.lowerQuartileAlert ? [{
                    text: 'Low score',
                    style: 'lowerQuartile',
                }] : []),

            ],
        }))
    }, [users, alerts])

    return {
        alertsList,
    }
}

export { useCurrentAlertsHook }
