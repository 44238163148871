import {AlertsState, CurrentAlertsAction} from "./types";
import {AnyAction} from "redux";

const INITIAL_STATE: AlertsState = {
    isLoading: true,
    current: []
}

const alertsReducer = (state = INITIAL_STATE, action: AnyAction): AlertsState => {
    switch (action.type) {
        case CurrentAlertsAction.START:
            return {
                ...state,
                isLoading: true,
                current: []
            }
        case CurrentAlertsAction.SUCCESS:
            return {
                ...state,
                isLoading: false,
                current: action.payload,
            }
        case CurrentAlertsAction.FAILURE:
            return {
                ...state,
                isLoading: false,
                current: [],
            }
        default:
            return state
    }
}

export { alertsReducer }
