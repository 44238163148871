import {rootReducer} from "./root-reducer";
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['config']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    compose(
        applyMiddleware(thunk)
    )
)

const persistor = persistStore(store)

export { store, persistor }
