import {DashboardAction, DashboardState} from "./types";
import {Action, AnyAction} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import DashboardService from "../../api/DashboardService";
import {setRedirect} from "../root-actions";
import {mapApiDashboardUserData, mapApiScoreData} from "../../api/api.helpers";
import {DashboardData} from "../../api/types";
import {getIsDashboardDataValid} from "./dashboard.selectors";
import {RootState} from "../types";

export const setDashboardStart = (): Action => ({
    type: DashboardAction.START,
})

export const setDashboardSuccess = (payload: DashboardData): AnyAction => ({
    type: DashboardAction.SUCCESS,
    payload,
})

export const setDashboardFailure = (payload: Error): AnyAction => ({
    type: DashboardAction.FAILURE,
    payload,
})

export const doStartDashboard = (): ThunkAction<Promise<void>, RootState, null, AnyAction> => {
    return async (dispatch: ThunkDispatch<DashboardState, null, Action>, getState): Promise<void> => {
        if (getIsDashboardDataValid(getState())) {
            return
        }
        dispatch(setDashboardStart())
        DashboardService.getDashboard()
            .then((response) => {
                const redirect = response.headers["x-redirect"];
                if (redirect) {
                    dispatch(setRedirect(redirect))
                }
                dispatch(setDashboardSuccess({
                    userName: response.data.userName,
                    team: response.data.team,
                    scores: response.data.scores.map(mapApiScoreData),
                    users: response.data.users.map(mapApiDashboardUserData),
                }))
            })
            .catch((error) => {
                dispatch(setDashboardFailure(error))
            })
    }
}
