import {connect} from 'react-redux'
import {Dashboard} from "./Dashboard";
import {getDashboardState} from "../../redux/root-selectors";
import {DashboardDispatchProps, DashboardStateProps} from "./types";
import {RootState} from "../../redux/types";
import {getDays, getWeeklyScores} from "../../redux/dashboard/dashboard.selectors";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {doStartDashboard} from "../../redux/dashboard/dashboard.actions";
import {doGetCurrentAlerts} from "../../redux/alerts/alerts.actions";

const mapStateToProps = (state: RootState): DashboardStateProps => ({
    ...getDashboardState(state),
    days: getDays(state),
    weeklyScores: getWeeklyScores(state),
})

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, Action>): DashboardDispatchProps => ({
    doStartDashboard: () => dispatch(doStartDashboard()),
    doGetCurrentAlerts: () => dispatch(doGetCurrentAlerts())
})
/* eslint-enable @typescript-eslint/no-explicit-any */

const DashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard)

export { DashboardContainer }
