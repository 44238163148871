import React, {FC} from "react"
import {DashboardProps} from "./types"
import {useDashboardHook} from "./Dashboard.hook"
import {Column, PageWidth, Row} from "../../components/Layout"
import {Card} from "../../components/Card"
import {ScoreTable} from "../../components/ScoreTable"
import {Size} from "../../components/Layout/types"
import colors from '../../styles/_colors.module.scss'
import styles from './Dashboard.styles.module.scss'
import {Header} from "../../components/Header";
import {Feedback} from "../../components/Feedback";
import {CurrentAlerts} from "../../components/CurrentAlerts";
import {AverageScoreGraph} from "../../components/AverageScoreGraph";

const Dashboard: FC<DashboardProps> = (props) => {
    const hookProps = useDashboardHook(props)
    const {
        isLoading,
        isAuthorised,
        weeklyScores,
        days,
    } = hookProps

    return (
        <PageWidth>
            <Feedback/>
            <Header title="Welcome to your HappyTrack dashboard"/>
            {isLoading && (
                <div>Loading...</div>
            )}
            {!isLoading && !isAuthorised && (
                <h3>Not authorized please try again</h3>
            )}
            {!isLoading && isAuthorised && (
                <>
                    <Row>
                        <Column size={Size.twoThirds}>
                            <Card className={styles.scoreCard}>
                                <ScoreTable
                                    days={days}
                                    colorRange={[
                                        colors.htRangeStart,
                                        colors.htRangeMid,
                                        colors.htRangeEnd,
                                    ]}
                                    weeklyScores={weeklyScores}
                                />
                            </Card>
                            <Card>
                                <AverageScoreGraph />
                            </Card>
                        </Column>
                        <Column size={Size.third}>
                            <Card variableHeight={true}>
                                <CurrentAlerts />
                            </Card>
                        </Column>
                    </Row>
                </>
            )}
        </PageWidth>
    )
}

export { Dashboard }
