import React, {ReactElement} from "react";
import styles from './CurrentAlerts.styles.module.scss';
import {CurrentAlertsProps} from "./types";
import {useCurrentAlertsHook} from "./CurrentAlerts.hooks";
import {UserLabel} from "../UserLabel";

const CurrentAlerts = (props: CurrentAlertsProps): ReactElement => {
    const {
        alertsList,
    } = useCurrentAlertsHook(props)
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                For your attention
            </div>
            <div className={styles.bodySmall}>
                Users that you may want to check in with include
            </div>
            <div className={styles.list}>
                {alertsList.length === 0 && (
                    <div className={styles.body}>
                        No notable users yet today!
                    </div>
                )}
                {alertsList.length > 0 && (
                    alertsList.map(item => (
                        <div className={styles.listItem} key={item.user.id}>
                            <UserLabel user={item.user} />
                            <div className={styles.alertList}>
                                {item.alerts.map(alert => (
                                    <div className={styles[alert.style]} key={alert.style}>
                                        {alert.text}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export { CurrentAlerts }
