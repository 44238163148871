import styles from "./ScoreTable.styles.module.scss";
import {UserLabel} from "../UserLabel";
import React, {ReactElement} from "react";
import {UserCellProps} from "./type";
import {withModal} from "../Modal/Modal";
import {UserScoreGraph} from "../UserScoreGraph";

const ModalGraph = withModal(UserScoreGraph, { autoSize: true })

const UserCell = ({ user }: UserCellProps): ReactElement =>
    <ModalGraph user={user}>
        <UserLabel className={styles.userLabel} user={user.summary} />
    </ModalGraph>

export { UserCell }
