const hexToRgb = (hex: string): {
    red: number,
    green: number,
    blue: number,
} | null => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
    } : null;
}

const linearInterpolation = (start: string, end: string, position: number) => {
    if (!start || !end) {
        return '#808080'
    }
    const ah = parseInt(start.replace(/#/g, ''), 16),
        ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
        bh = parseInt(end.replace(/#/g, ''), 16),
        br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
        rr = ar + position * (br - ar),
        rg = ag + position * (bg - ag),
        rb = ab + position * (bb - ab);
    return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

const getInterpolatedColor = (
    colors: string[],
    position: number,
    min = 0,
    max = 1,
): string => {
    const range = max - min
    const step = range / (colors.length - 1)
    for (let index = 0; index < colors.length - 1; index++) {
        const thisRangeStart = index * step
        const nextRangeStart = (index + 1) * step
        if (position === thisRangeStart) {
            return colors[index]
        } else if (position > thisRangeStart && position < nextRangeStart) {
            return linearInterpolation(
                colors[index],
                colors[index + 1],
                1 / step * (position - thisRangeStart)
            )
        }
    }
    return colors[colors.length -1]
}

const getContrastingTextColor = (
    backgroundColor: string,
): string => {
    const { red, green, blue } = hexToRgb(backgroundColor) || {
        red: 0,
        green: 0,
        blue: 0,
    }
    return (red * 0.299 + green * 0.587 + blue * 0.114) > 150
        ? '#000000'
        : '#ffffff'
}

export { getInterpolatedColor, getContrastingTextColor }
