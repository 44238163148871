 import {ModalHookProps, ModalProps} from './types'
 import { useEffect, useState } from 'react'

 export const useModalHooks = (props: ModalProps): ModalHookProps => {
     const [shouldRenderContent, setShouldRenderContent] = useState(props.isOpen)
     const [isClosing, setIsClosing] = useState(false)
     useEffect(() => {
         if (props.isOpen) {
             setIsClosing(false)
             setShouldRenderContent(true)
         } else {
             setIsClosing(true)
             if (shouldRenderContent) {
                 setTimeout(() => setShouldRenderContent(false), 500)
             }
         }
     }, [props.isOpen, shouldRenderContent])
     return {
         ...props,
         shouldRenderContent,
         isClosing,
     }
 }
