import {connect} from "react-redux";
import {RootState} from "../../redux/types";
import {getWeeklyScores} from "../../redux/dashboard/dashboard.selectors";
import {AverageScoreGraph} from "./AverageScoreGraph";
import {AverageScoreGraphStateProps} from "./types";

const mapStateToProps = (state: RootState): AverageScoreGraphStateProps => ({
    weeklyScores: getWeeklyScores(state)
})

const AverageScoreGraphContainer = connect(
    mapStateToProps,
)(AverageScoreGraph)

export { AverageScoreGraphContainer }
