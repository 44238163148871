import {DashboardAction, DashboardState} from "./types";
import {AnyAction} from "redux";

const INITIAL_STATE = {
    isLoading: true,
    isAuthorised: false,
    dayRange: 6,
    data: null,
}

const dashboardReducer = (state = INITIAL_STATE, action: AnyAction): DashboardState => {
    switch (action.type) {
        case DashboardAction.START:
            return INITIAL_STATE
        case DashboardAction.SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthorised: !!action.payload.team,
                data: {
                    userName: action.payload.userName,
                    team: action.payload.team,
                    scores: action.payload.scores,
                    users: action.payload.users,
                },
            }
        case DashboardAction.FAILURE:
            return {
                ...INITIAL_STATE,
                isLoading: false,
            }
        default:
            return state
    }
}

export { dashboardReducer }
