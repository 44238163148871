import React, {ReactElement} from 'react'
import styles from './Feedback.styles.module.scss'
import FeedbackIcon from './FeedbackIcon.png'

const Feedback = (): ReactElement => (
    <div className={styles.link}>
        <a href="https://happy-track.app/" target="_blank" rel="noreferrer" className={styles.linkUnderline}>
            <div className={styles.container}>
                <div className={styles.wrapIconText}>
                    <img className={styles.image} src={FeedbackIcon} alt="Feedback Icon"/>
                    <div className={styles.text}>
                        Feedback
                    </div>
                </div>
            </div>
        </a>
    </div>
)

export { Feedback }
