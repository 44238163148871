import {RootState} from "./types";
import {DashboardState} from "./dashboard/types";
import {NavigationState} from "./navigation/types";
import {AlertsState} from "./alerts/types";
import {ConfigState} from "./config/types";

export const getDashboardState = (state: RootState): DashboardState => state.dashboard
export const getNavigationState = (state: RootState): NavigationState => state.navigation
export const getAlertsState = (state: RootState): AlertsState => state.alerts
export const getConfigState = (state: RootState): ConfigState => state.config
