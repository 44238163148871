import React, {ReactElement} from 'react'
import {useUserSearchSelectorHook} from "./UserSearchSelector.hooks";
import {UserSearchSelectorProps} from "./types";
import styles from './UserSearchSelector.styles.module.scss'
import {UserPill} from "./UserPill";
import {SearchableInput} from "../SearchableInput";

const UserSearchSelector = (props: UserSearchSelectorProps): ReactElement => {
    const {
        isLoading,
        userType,
        placeholder,
        currentUsers,
        usersToModify,
        newUserSet,
        modifyListTitle,
        isRemovingLastModifiedUser,
        fetchSearchData,
        processSearchData,
        searchableString,
        minSearchPhraseLength,
        handleUserModification,
        handleSubmitChanges,
    } = useUserSearchSelectorHook(props)
    return (
        <div className={styles.container}>
            <SearchableInput
                placeholder={placeholder}
                fetchSearchData={fetchSearchData}
                processSearchData={processSearchData}
                searchableString={searchableString}
                minSearchPhraseLength={minSearchPhraseLength}
                resultsContainerClassName={styles.searchResults}
                allowKeyboardSelection={true}
                preselectFirstResult={true}
                onKeyboardSelection={({result: user, clearSearch}) => {
                    handleUserModification(user)
                    clearSearch()
                }}
                emptyMessage={search => (
                    <div className={styles.emptyResults}>
                        No results for {search}
                    </div>
                )}
            >
                {({result: user, clearSearch, isSelected}) => (
                    <div className={`
                            ${styles.result}
                            ${isSelected ? styles.selected : ''}
                        `} key={user.user.id}>
                        <UserPill
                            className={`
                                ${styles.pill}
                            `}
                            {...user}
                            onClick={() => {
                                handleUserModification(user)
                                clearSearch()
                            }}
                        />
                    </div>
                )
                }
            </SearchableInput>
            {usersToModify.length > 0 && (
                <div className={`
                    ${styles.section}
                    ${isRemovingLastModifiedUser ? styles.closing : ''}
                `}>
                    <div className={styles.listTitle}>{modifyListTitle}</div>
                    <div className={styles.addRemoveUsers}>
                        {usersToModify.map(user => (
                            <div className={`
                                ${styles.pill}
                                ${user.isRemoving ? styles.removing : ''}
                            `} key={user.user.id}>
                                <UserPill
                                    {...user}
                                    onClick={() =>
                                        handleUserModification(user)
                                    }
                                />
                            </div>
                        ))}
                    </div>
                    <hr />
                </div>
            )}
            {isLoading && (
                <div className={styles.listTitle}>Loading {userType}...</div>
            )}
            {!isLoading && currentUsers.length === 0 && (
                <div className={styles.body}>No current {userType.toLowerCase()}. Search for {userType.toLowerCase()} to
                    add.</div>
            )}
            {!isLoading && currentUsers.length > 0 && (
                <>
                    <div className={styles.listTitle}>Current {userType}</div>
                    <div className={styles.currentUsers}>
                        {currentUsers && currentUsers.map(user => (
                            <UserPill
                                key={user.user.id}
                                {...user}
                                className={styles.pill}
                                onClick={() => handleUserModification(user)}
                            />
                        ))}
                    </div>
                </>
            )}
            {usersToModify.length > 0 && (
                <div className={styles.actionsContainer}>
                    <button
                        className={styles.primaryButton}
                        onClick={() => handleSubmitChanges?.(newUserSet, usersToModify)}
                    >
                        Save
                    </button>
                </div>
            )}
        </div>
    )
}

export { UserSearchSelector }
